import gql from 'graphql-tag';

export const CREATE_GRANT = gql`
  mutation createGrant($input: GrantInput!) {
    createGrant(input: $input) {
      _id
      noticereference
      extractiondate
      grantedto
      solicitor
      value
    }
  }
`;
