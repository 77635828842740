import React, { useContext, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Menu, Input, Icon } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFile,
  faSearch,
  faFolder,
  faBan,
  faGlobe,
} from '@fortawesome/pro-light-svg-icons';
import isEmpty from 'lodash.isempty';
import pickBy from 'lodash.pickby';
import MainPage from '../../main/MainPage.jsx';
import DefaultNoticesTable from './DefaultNoticesTable.jsx';
import QuickSearchNoticesTable from './QuickSearchNoticesTable.jsx';
import { AppContext } from '../../../AppContext';

const KEYCODE_ENTER = 13;

function NoticesList() {
  const {
    setAppState,
    noticesSearchTerms,
    noticesQuickSearchTerm,
  } = useContext(AppContext);

  const history = useHistory();

  const quickSearchInput = useRef(null);

  return (
    <MainPage title="Notices">
      <Menu attached="top">
        <Menu.Item
          as="a"
          name="New"
          onClick={() => history.push('/notices/add')}
        >
          <FontAwesomeIcon icon={faFile} style={{ marginRight: '5px' }} />
          New
        </Menu.Item>

        <Menu.Item
          as="a"
          name="Search"
          onClick={() => history.push('/notices/search')}
        >
          <FontAwesomeIcon icon={faSearch} style={{ marginRight: '5px' }} />
          Search
        </Menu.Item>

        {!isEmpty(noticesSearchTerms) && (
          <Menu.Item
            as="a"
            name="Create Working Set"
            onClick={() => history.push('/notices/workingset')}
          >
            <FontAwesomeIcon icon={faFolder} style={{ marginRight: '5px' }} />
            Create Working Set
          </Menu.Item>
        )}

        {!isEmpty(pickBy(noticesSearchTerms)) && (
          <Menu.Item
            as="a"
            name="Clear Search"
            onClick={() => {
              setAppState('noticesPageSize', 15);
              setAppState('noticesSearchTerms', {});
              setAppState('noticesCurrentPage', 0);
            }}
          >
            <FontAwesomeIcon
              icon={faBan}
              style={{ marginRight: '5px' }}
              color="red"
            />
            Clear Search
          </Menu.Item>
        )}

        {(!isEmpty(noticesSearchTerms) || !isEmpty(noticesQuickSearchTerm)) && (
          <Menu.Item
            as="a"
            name="Show All"
            onClick={() => {
              setAppState('noticesPageSize', 0);
              setAppState('noticesCurrentPage', 0);
            }}
          >
            <FontAwesomeIcon icon={faGlobe} style={{ marginRight: '5px' }} />
            Show All
          </Menu.Item>
        )}

        <Menu.Menu position="right">
          <Menu.Item>
            <Input
              ref={quickSearchInput}
              placeholder="Quick Search..."
              onKeyUp={e => {
                if (e.keyCode === KEYCODE_ENTER) {
                  setAppState('noticesQuickSearchTerm', e.target.value);
                }
              }}
              icon={
                <Icon
                  name={noticesQuickSearchTerm ? 'x' : 'search'}
                  link={!!noticesQuickSearchTerm}
                  circular={!!noticesQuickSearchTerm}
                  color={noticesQuickSearchTerm ? 'red' : 'black'}
                  onClick={() => {
                    quickSearchInput.current.value = ''; // TODO: fix, not clearing
                    setAppState('noticesQuickSearchTerm', '');
                    setAppState('noticesCurrentPage', 0);
                  }}
                />
              }
            />
          </Menu.Item>
        </Menu.Menu>
      </Menu>

      {noticesQuickSearchTerm ? (
        <QuickSearchNoticesTable />
      ) : (
        <DefaultNoticesTable />
      )}
    </MainPage>
  );
}

export default NoticesList;
