import gql from 'graphql-tag';

export const GRANT = gql`
  query Grant($_id: ObjectID!) {
    grant(_id: $_id) {
      _id
      noticereference
      extractiondate
      grantedto
      solicitor
      value
    }
  }
`;

export const UPDATE_GRANT = gql`
  mutation updateGrant($input: GrantInput!) {
    updateGrant(input: $input) {
      _id
      noticereference
      extractiondate
      grantedto
      solicitor
      value
    }
  }
`;

export const NOTICE = gql`
  query Notice($reference: String!) {
    notice(reference: $reference) {
      _id
      noticedate
      source
      informant
      sex
      reference
      familyname
      familynameakas
      maidenname
      firstnames
      firstnameakas
      livedat
      diedat
      diedon
      estatevalue
    }
  }
`;

export const SOLICITORS = gql`
  query solicitors($skip: Int, $limit: Int) {
    solicitors(skip: $skip, limit: $limit) {
      _count
      solicitors {
        _id
        name
      }
    }
  }
`;
