import React from 'react';
import { useQuery } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';
import { FILES } from './files/gql';

function NoticeMenu({
  active,
  history,
  match: {
    params: { reference },
  },
}) {
  const { loading, data } = useQuery(FILES, {
    variables: { reference },
  });

  if (loading) return null;
  
  const { notice: { files }  } = data;

  function handleTab(name) {
    history.push(`/notice/${reference}/${name}`);
  }

  return (
    <Menu tabular attached="top">
      <Menu.Item
        active={active === 'details'}
        as="a"
        name="details"
        onClick={() => handleTab('details')}
      >
        Details
      </Menu.Item>
      <Menu.Item
        active={active.includes('grant')}
        as="a"
        name="grants"
        onClick={() => handleTab('grants')}
      >
        Grants
      </Menu.Item>
      <Menu.Item
        active={active === 'notes'}
        as="a"
        name="notes"
        onClick={() => handleTab('notes')}
      >
        Notes
      </Menu.Item>
      <Menu.Item
        active={active === 'workingsets'}
        as="a"
        name="workingsets"
        onClick={() => handleTab('workingsets')}
      >
        Working Sets
      </Menu.Item>
      <Menu.Item
        active={active === 'files'}
        as="a"
        name="files"
        onClick={() => handleTab('files')}
      >
        Files ({files.length})
      </Menu.Item>
    </Menu>
  );
}

export default withRouter(NoticeMenu);
