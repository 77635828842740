import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Form, Button } from 'semantic-ui-react';
import { Field, reduxForm } from 'redux-form';
import Validator from 'validatorjs';
import { renderInput } from '../../common/forms';
import { SOLICITORS } from '../SolicitorsTable';
import { AppContext } from '../../../AppContext';

export const ADD_SOLICITOR = gql`
  mutation createSolicitor($input: SolicitorInput!) {
    createSolicitor(input: $input) {
      _id
      name
      lowerCaseName
    }
  }
`;

const SolicitorsAddForm = props => {
  const { handleSubmit, pristine, valid, history } = props;
  const [createSolicitor] = useMutation(ADD_SOLICITOR);
  const { sourcesPageSize, sourcesCurrentPage } = useContext(AppContext);

  async function submit(values) {
    await createSolicitor({
      variables: { input: values },
      refetchQueries: [
        {
          query: SOLICITORS,
          variables: {
            skip: sourcesPageSize * sourcesCurrentPage,
            limit: sourcesPageSize,
          },
        },
      ],
    });
    history.push('/solicitors/');
  }

  return (
    <Form onSubmit={handleSubmit(submit)} noValidate>
      <Form.Group inline>
        <Field required name="name" label="Name" component={renderInput} />
      </Form.Group>
      <Button type="submit" disabled={!valid || pristine}>
        Submit
      </Button>
    </Form>
  );
};

const validate = data => {
  const rules = {
    name: 'required|min:3',
  };

  const messages = {
    'required.name': 'Solicitor name is required.',
    'min.name': 'Solicitor name must be at least 3 characters.',
  };

  const validator = new Validator(data, rules, messages);

  return validator.passes() ? {} : validator.errors.all();
};

export default withRouter(
  reduxForm({
    form: 'solicitorsAddForm',
    validate,
    initialValues: {
      name: '',
    },
  })(SolicitorsAddForm)
);
