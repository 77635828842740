import React, { useState, useRef } from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import {
  Sidebar,
  Header,
  Segment,
  Form,
  Button,
  Input,
  Dropdown,
  Loader,
} from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';

const SEARCH_VALUES = gql`
  fragment SelectOptionParts on SelectOption {
    key
    value
    text
  }

  query SearchValues {
    places {
      ...SelectOptionParts
    }
    counties {
      ...SelectOptionParts
    }
    countries {
      ...SelectOptionParts
    }
    types {
      ...SelectOptionParts
    }
    topics {
      ...SelectOptionParts
    }
    collections {
      ...SelectOptionParts
    }
  }
`;

function SearchBar({ visible, onClose, onSearch }) {
  const [keywords, setKeywords] = useState('');
  const [author, setAuthor] = useState('');
  const [places, setPlaces] = useState([]);
  const [counties, setCounties] = useState([]);
  const [countries, setCountries] = useState([]);
  const [types, setTypes] = useState([]);
  const [topics, setTopics] = useState([]);
  const [collections, setCollections] = useState([]);
  const keywordsInputField = useRef(null);

  const { data, loading } = useQuery(SEARCH_VALUES);

  if (loading) return <Loader active />;

  const {
    places: allPlaces,
    counties: allCounties,
    countries: allCountries,
    types: allTypes,
    topics: allTopics,
    collections: allCollections,
  } = data;

  function handleSearch() {
    const query = {
      keywords,
      author,
      places,
      counties,
      countries,
      topics,
      types,
      collections,
    };

    onSearch(query);
  }

  function handleClear() {
    setKeywords('');
    setAuthor('');
    setPlaces([]);
    setCounties([]);
    setCountries([]);
    setTypes([]);
    setTopics([]);
    setCollections([]);

    onSearch({});
  }

  function handleSearchBarShown() {
    keywordsInputField.current.focus();
  };

  return (
    <Sidebar
      style={{ backgroundColor: 'white' }}
      animation="overlay"
      direction="right"
      icon="labeled"
      visible={visible}
      width="very wide"
      onShow={handleSearchBarShown}
    >
      <Segment basic>
        <Header as="h3">
          Search Library
          <FontAwesomeIcon
            className="actionIcon"
            icon={faTimes}
            pull="right"
            size="lg"
            onClick={onClose}
          />
        </Header>
        <Form style={{ marginTop: '40px' }}>
          <Form.Field>
            <Input
              placeholder="Keyword(s)"
              name="keywords"
              value={keywords}
              onChange={(e, { value }) => setKeywords(value)}
              ref={keywordsInputField}
            />
          </Form.Field>
          <Form.Field>
            <Input
              placeholder="Author"
              name="author"
              value={author}
              onChange={(e, { value }) => setAuthor(value)}
            />
          </Form.Field>
          <Form.Field>
            <Dropdown
              lazyLoad
              placeholder="Town/City"
              name="places"
              fluid
              multiple
              search
              selection
              options={allPlaces}
              value={places}
              onChange={(e, { value }) => setPlaces(value)}
            />
          </Form.Field>
          <Form.Field>
            <Dropdown
              lazyLoad
              placeholder="County"
              name="counties"
              fluid
              multiple
              search
              selection
              options={allCounties}
              value={counties}
              onChange={(e, { value }) => setCounties(value)}
            />
          </Form.Field>
          <Form.Field>
            <Dropdown
              lazyLoad
              placeholder="Country"
              name="countries"
              fluid
              multiple
              search
              selection
              options={allCountries}
              value={countries}
              onChange={(e, { value }) => setCountries(value)}
            />
          </Form.Field>
          <Form.Field>
            <Dropdown
              lazyLoad
              placeholder="Type"
              name="types"
              fluid
              multiple
              search
              selection
              options={allTypes}
              value={types}
              onChange={(e, { value }) => setTypes(value)}
            />
          </Form.Field>
          <Form.Field>
            <Dropdown
              lazyLoad
              placeholder="Topic"
              name="topics"
              fluid
              multiple
              search
              selection
              options={allTopics}
              value={topics}
              onChange={(e, { value }) => setTopics(value)}
            />
          </Form.Field>
          <Form.Field>
            <Dropdown
              lazyLoad
              placeholder="Collection"
              name="collections"
              fluid
              multiple
              search
              selection
              options={allCollections}
              value={collections}
              onChange={(e, { value }) => setCollections(value)}
            />
          </Form.Field>
          <Button primary onClick={handleSearch}>
            Search
          </Button>
          <Button secondary onClick={handleClear}>
            Clear
          </Button>
        </Form>
      </Segment>
    </Sidebar>
  );
}

export default SearchBar;
