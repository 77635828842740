import React, { useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Loader } from 'semantic-ui-react';
import { AppContext } from '../../../AppContext';
import NoticesTable from './NoticesTable.jsx';
import { NOTICES_QUICK_SEARCH } from './gql';

function QuickSearchNoticesTable() {
  const {
    noticesCurrentPage,
    noticesSortColumn,
    noticesSortDirection,
    noticesPageSize,
    noticesQuickSearchTerm,
  } = useContext(AppContext);

  const { loading, data } = useQuery(NOTICES_QUICK_SEARCH, {
    skip: !noticesQuickSearchTerm,
    fetchPolicy: 'cache-and-network',
    variables: {
      searchTerm: noticesQuickSearchTerm,
      limit: noticesPageSize,
      skip: noticesPageSize * noticesCurrentPage,
      sort: {
        column: noticesSortColumn,
        direction: noticesSortDirection,
      },
    },
  });

  if (!noticesQuickSearchTerm) return null;

  if (loading) {
    return (
      <div style={{ padding: '20px' }}>
        <Loader active />
      </div>
    );
  }

  const { noticesQuickSearch: notices } = data;

  return (
    <NoticesTable
      loading={loading}
      notices={notices.notices}
      count={notices._count}
    />
  );
}

export default QuickSearchNoticesTable;
