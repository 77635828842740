import React from 'react';
import MainPage from '../main/MainPage.jsx';
import UsersTable from './UsersTable.jsx';

const UsersPage = props => (
  <MainPage title="Users">
    <UsersTable {...props} />
  </MainPage>
);

export default UsersPage;
