import gql from 'graphql-tag';

export const GRANTS = gql`
  query Grants($query: GrantsQuery, $skip: Int, $limit: Int, $sort: SortSpec) {
    grants(query: $query, skip: $skip, limit: $limit, sort: $sort) {
      _count
      grants {
        _id
        deleted
        noticereference
        extractiondate
        grantedto
        solicitor
        familyname
        firstnames
        value
      }
    }
  }
`;

export const DELETE_GRANT = gql`
  mutation deleteGrant($_id: ObjectID!) {
    deleteGrant(_id: $_id) {
      _id
      deleted
      noticereference
      extractiondate
      grantedto
      solicitor
      familyname
      firstnames
      value
    }
  }
`;
