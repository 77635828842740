import React from 'react';
import gql from 'graphql-tag';
import { Mutation } from '@apollo/react-components';
import { Grid } from 'semantic-ui-react';
import MainPage from '../../main/MainPage.jsx';
import NoticeEditForm from './NoticeEditForm.jsx';

const UPDATE_NOTICE = gql`
  mutation UpdateNotice($_id: ObjectID!, $input: NoticeInput!) {
    updateNotice(_id: $_id, input: $input) {
      _id
      noticedate
      source
      informant
      sex
      reference
      familyname
      familynameakas
      maidenname
      firstnames
      firstnameakas
      livedat
      diedat
      diedon
      estatevalue
    }
  }
`;

function NoticeEditPage() {
  return (
    <MainPage title="Edit Notice">
      <Grid columns="equal">
        <Grid.Column />
        <Grid.Column width={10}>
          <Mutation mutation={UPDATE_NOTICE}>
            {updateNotice => (
              <NoticeEditForm
                updateNotice={updateNotice}
              />
            )}
          </Mutation>
        </Grid.Column>
        <Grid.Column />
      </Grid>
    </MainPage>
  );
}

export default NoticeEditPage;
