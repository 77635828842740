import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useHistory, useParams } from 'react-router-dom';
import { Table, Message, Loader } from 'semantic-ui-react';
import { WORKING_SETS } from './gql';

function NoticeWorkingSetsTable() {
  const history = useHistory();
  const { reference } = useParams();
  const { loading, data } = useQuery(WORKING_SETS, {
    variables: { reference },
  });

  if (loading) return <Loader active />;

  const { notice: { workingsets: workingSets = [] } = {} } = data;

  if (workingSets.length === 0) {
    return <Message size="large">No Working Sets for this Notice</Message>;
  }

  return (
    <Table celled selectable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Name</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {!loading &&
          workingSets.map(workingSet => (
            <Table.Row
              key={workingSet.name}
              onClick={() => history.push(`/workingset/${workingSet.name}`)}
            >
              <Table.Cell>{workingSet.name}</Table.Cell>
            </Table.Row>
          ))}
      </Table.Body>
    </Table>
  );
}

export default NoticeWorkingSetsTable;
