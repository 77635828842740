import React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { Form, Header, Segment, Grid, Button } from 'semantic-ui-react';
import NoticeWorkingSetsAddSelect from './NoticeWorkingSetsAddSelect.jsx';
import withAddNoticeToWorkingSet from './withAddNoticeToWorkingSet.js';

class NoticeWorkingSetsAdd extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      disabled: true,
      workingSetName: '',
    };
  }

  handleChange = (_, data) => {
    this.setState({
      disabled: false,
      workingSetName: data.value,
    });
  };

  handleClick = async () => {
    const {
      addNoticeToWorkingSet,
      match: {
        params: { reference },
      },
      history,
    } = this.props;

    await addNoticeToWorkingSet(reference, this.state.workingSetName);

    history.push(`/notice/${reference}/workingsets`);
  };

  render() {
    return (
      <div>
        <Header as="h5" attached="top">
          Add Notice to Working Set
        </Header>
        <Segment attached>
          <Grid columns="equal">
            <Grid.Column />
            <Grid.Column width={10}>
              <Form>
                <Form.Field>
                  <NoticeWorkingSetsAddSelect onChange={this.handleChange} />
                </Form.Field>
                <Button
                  disabled={this.state.disabled}
                  onClick={this.handleClick}
                >
                  Add
                </Button>
              </Form>
            </Grid.Column>
            <Grid.Column />
          </Grid>
        </Segment>
      </div>
    );
  }
}

export default compose(
  withRouter,
  withAddNoticeToWorkingSet,
)(NoticeWorkingSetsAdd);
