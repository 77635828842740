import React, { useEffect, useState } from 'react';
import { Segment, Button } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';

const FIVE_MINUTES = 5000 * 60;

export default function AppVersion() {
  const [outdated, setOutdated] = useState(false);

  useEffect(() => {
    const handle = setInterval(async () => {
      try {
        const response = await fetch('/version.json');
        const versionData = await response.json();

        setOutdated(
          versionData.currentVersion !== process.env.REACT_APP_CI_COMMIT_SHORT_SHA
        );
      } catch (e) {
        // do nothing, we'll just try again later
      }
    }, FIVE_MINUTES);

    return function cleanup() {
      clearInterval(handle);
    };
  }, []);

  return (
    <Segment
      size="mini"
      secondary
      textAlign="center"
      color={outdated ? 'yellow' : null}
    >
      {outdated ? (
        <>
          <FontAwesomeIcon
            icon={faExclamationTriangle}
            size="lg"
            style={{ marginLeft: '5px' }}
          />
          <br />
          New version available.
          <Button
            size={'tiny'}
            style={{ marginTop: '10px' }}
            color="green"
            fluid
            onClick={() => window.location.reload()}
          >
            Reload
          </Button>
        </>
      ) : (
        <>
          {'You are running version: '}
          <span style={{ fontFamily: 'monospace' }}>
            {process.env.REACT_APP_CI_COMMIT_SHORT_SHA || 'development'}
          </span>
        </>
      )}
    </Segment>
  );
}
