import React from 'react';
import { Comment } from 'semantic-ui-react';
import moment from 'moment';
import useRole from '../../utils/useRole';

function WorkingSetNotes({ notes = [], showingAll }) {
  const { loading, role } = useRole();

  if (loading) return null;

  return (
    <Comment.Group>
      {notes
        .filter(note => !note.private || ['admin', 'senior'].includes(role))
        .sort((a, b) => {
          if (a.date > b.date) return -1;
          if (a.date < b.date) return 1;
          return 0;
        })
        .slice(0, showingAll ? undefined : 3)
        .map(note => (
          <Comment key={`${note.user}-${note.date}`}>
            <Comment.Content>
              <Comment.Author as="span">{note.user}</Comment.Author>
              <Comment.Metadata>
                <div>
                  {!note.date || note.date === -1
                    ? 'Historical Note'
                    : moment(note.date).format('DD/MM/YY')}
                </div>
              </Comment.Metadata>
              <Comment.Text>
                <div
                  dangerouslySetInnerHTML={{
                    __html: note.text.replace(/\n/g, '<br />'),
                  }}
                />
              </Comment.Text>
            </Comment.Content>
          </Comment>
        ))}
    </Comment.Group>
  );
}

export default WorkingSetNotes;
