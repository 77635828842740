import gql from 'graphql-tag';

export const SOURCES = gql`
  query sources {
    sources {
      _id
      name
    }
  }
`;
