import React from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useParams, useHistory } from 'react-router-dom';
import {
  Form,
  Button,
  Input,
  Dropdown,
  Message,
  Loader,
} from 'semantic-ui-react';
import { Formik } from 'formik';
import get from 'lodash.get';
import * as yup from 'yup';
import 'react-datepicker/dist/react-datepicker.css';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import accounting from 'accounting';
import NoticeDateDatePicker from './NoticeDateDatePicker';
import DiedOnDatePicker from './DiedOnDatePicker';
import { UPDATE_NOTICE, NOTICE, SOURCES } from './gql';

function NoticeEditForm() {
  const { reference } = useParams();
  const history = useHistory();

  const { loading: noticeLoading, data: noticeData } = useQuery(NOTICE, {
    variables: { reference },
  });

  const { loading: sourcesLoading, data: sourcesData } = useQuery(SOURCES);
  const [updateNotice] = useMutation(UPDATE_NOTICE);

  if (noticeLoading || !noticeData) return <Loader active />;
  if (sourcesLoading || !sourcesData) return <Loader active />;

  const { notice } = noticeData;
  const { sources } = sourcesData;

  // Correct for some truly horrible dates that are Unix epochs as strings!
  let noticeDate = new Date(notice.noticedate);

  if (noticeDate.toString() === 'Invalid Date') {
    noticeDate = new Date(parseInt(notice.noticedate, 10));
  }

  let diedOn = null;

  if (notice.diedon) {
    diedOn = new Date(notice.diedon);

    if (diedOn.toString() === 'Invalid date') {
      diedOn = new Date(parseInt(notice.diedon, 10));
    }
  }

  return (
    <Formik
      enableReinitialize
      validateOnChange
      validateOnBlur
      initialValues={{
        noticeDate,
        source: get(notice, 'source', ''),
        informant: get(notice, 'informant', ''),
        familyName: get(notice, 'familyname', ''),
        familyNameAkas: get(notice, 'familynameakas', ''),
        maidenName: get(notice, 'maidenname', ''),
        firstNames: get(notice, 'firstnames', ''),
        firstNameAkas: get(notice, 'firstnameakas', ''),
        livedAt: get(notice, 'livedat', ''),
        diedAt: get(notice, 'diedat', ''),
        diedOn,
        estateValue: get(notice, 'estatevalue', ''),
        sexes: [
          { text: 'Male', value: 'Male' },
          { text: 'Female', value: 'Female' },

          { text: 'Unknown', value: 'Unknown' },
        ],
        sex: get(notice, 'sex', 'Unknown'),
        sources: sources.map(source => ({
          text: source.name,
          value: source.name,
        })),
      }}
      validationSchema={yup.object().shape({
        noticeDate: yup
          .date()
          .transform((value, originalValue) =>
            originalValue ? value : undefined
          )
          .required()
          .label('Notice date'),
        source: yup
          .string()
          .required()
          .label('Source'),
        familyName: yup
          .string()
          .required()
          .label('Family name'),
        firstNames: yup
          .string()
          .required()
          .label('First names'),
        diedOn: yup
          .date()
          .transform((value, originalValue) =>
            originalValue ? value : undefined
          )
          .label('Died on date'),
        estateValue: yup
          .number()
          .typeError('Estate value must be a numeric value')
          .label('Estate value'),
      })}
      onSubmit={async values => {
        const { sexes, sources, ...rest } = values;
        const { _id, __typename, reference, ...original } = notice;

        // Convert all the object keys to lower case and save
        const updates = Object.keys(rest).reduce(
          (acc, key) => ({
            [key.toLowerCase()]: rest[key],
            ...acc,
          }),
          {}
        );

        await updateNotice({
          variables: {
            _id,
            input: {
              ...original,
              ...updates,
            },
          },
        });

        history.push(`/notice/${reference}`);
      }}
    >
      {formik => {
        return (
          <Form onSubmit={formik.handleSubmit} noValidate error>
            <Form.Group inline>
              <Form.Field required>
                <label style={{ width: '150px' }}>Notice date</label>
                <div className="ui input">
                  <NoticeDateDatePicker
                    value={formik.values.noticeDate}
                    onChange={formik.setFieldValue}
                    onBlur={formik.setFieldTouched}
                    error={formik.errors.noticeDate}
                    touched={formik.touched.noticeDate}
                  />
                </div>
              </Form.Field>
            </Form.Group>

            <Form.Group inline>
              <Form.Field required>
                <label style={{ width: '150px' }}>Source</label>
                <Dropdown
                  style={{ width: '250px' }}
                  name="source"
                  placeholder="Select source"
                  selection
                  options={formik.values.sources}
                  value={formik.values.source}
                  onChange={(event, data) => {
                    formik.setFieldValue('source', data.value);
                  }}
                />
                {formik.touched.source && formik.errors.source && (
                  <Message error content={formik.errors.source} size="mini" />
                )}
              </Form.Field>
            </Form.Group>

            <Form.Group inline>
              <Form.Field>
                <label style={{ width: '150px' }}>Informant</label>
                <Input
                  style={{ width: '250px' }}
                  name="informant"
                  value={formik.values.informant || ''}
                  onChange={formik.handleChange}
                />
              </Form.Field>
            </Form.Group>

            <Form.Group inline>
              <Form.Field>
                <label style={{ width: '150px' }}>Sex</label>
                <Dropdown
                  style={{ width: '150px' }}
                  name="sex"
                  placeholder="Select sex"
                  selection
                  options={formik.values.sexes}
                  value={formik.values.sex}
                  onChange={(event, data) => {
                    formik.setFieldValue('sex', data.value);
                  }}
                />
              </Form.Field>
            </Form.Group>

            <Form.Group inline>
              <Form.Field required>
                <label style={{ width: '150px' }}>Family name</label>
                <Input
                  style={{ width: '250px' }}
                  name="familyName"
                  value={formik.values.familyName || ''}
                  onChange={formik.handleChange}
                />
                {formik.touched.familyName && formik.errors.familyName && (
                  <Message
                    error
                    content={formik.errors.familyName}
                    size="mini"
                  />
                )}
              </Form.Field>
            </Form.Group>

            <Form.Group inline>
              <Form.Field>
                <label style={{ width: '150px' }}>Family name AKAs</label>
                <Input
                  style={{ width: '250px' }}
                  name="familyNameAkas"
                  value={formik.values.familyNameAkas || ''}
                  onChange={formik.handleChange}
                />
              </Form.Field>
            </Form.Group>

            <Form.Group inline>
              <Form.Field>
                <label style={{ width: '150px' }}>Maiden name</label>
                <Input
                  style={{ width: '250px' }}
                  name="maidenName"
                  value={formik.values.maidenName || ''}
                  onChange={formik.handleChange}
                />
              </Form.Field>
            </Form.Group>

            <Form.Group inline>
              <Form.Field required>
                <label style={{ width: '150px' }}>First names</label>
                <Input
                  style={{ width: '250px' }}
                  name="firstNames"
                  value={formik.values.firstNames || ''}
                  onChange={formik.handleChange}
                />
                {formik.touched.firstNames && formik.errors.firstNames && (
                  <Message
                    error
                    content={formik.errors.firstNames}
                    size="mini"
                  />
                )}
              </Form.Field>
            </Form.Group>

            <Form.Group inline>
              <Form.Field>
                <label style={{ width: '150px' }}>First name AKAs</label>
                <Input
                  style={{ width: '250px' }}
                  name="firstNameAkas"
                  value={formik.values.firstNameAkas || ''}
                  onChange={formik.handleChange}
                />
              </Form.Field>
            </Form.Group>

            <Form.Group inline>
              <Form.Field>
                <label style={{ width: '150px' }}>Lived at</label>
                <Input
                  style={{ width: '250px' }}
                  name="livedAt"
                  value={formik.values.livedAt || ''}
                  onChange={formik.handleChange}
                />
              </Form.Field>
            </Form.Group>

            <Form.Group inline>
              <Form.Field>
                <label style={{ width: '150px' }}>Died at</label>
                <Input
                  style={{ width: '250px' }}
                  name="diedAt"
                  value={formik.values.diedAt || ''}
                  onChange={formik.handleChange}
                />
              </Form.Field>
            </Form.Group>

            <Form.Group inline>
              <Form.Field>
                <label style={{ width: '150px' }}>Died on</label>
                <div className="ui input">
                  <DiedOnDatePicker
                    value={formik.values.diedOn}
                    onChange={formik.setFieldValue}
                    onBlur={formik.setFieldTouched}
                    error={formik.errors.diedOn}
                    touched={formik.touched.diedOn}
                  />
                </div>
              </Form.Field>
            </Form.Group>

            <Form.Group inline>
              <Form.Field>
                <label style={{ width: '150px' }}>Estate value</label>
                <Input
                  style={{ width: '250px' }}
                  name="estateValue"
                  value={formik.values.estateValue || ''}
                  onChange={(event, data) => {
                    formik.setFieldValue(
                      'estateValue',
                      accounting.unformat(data.value)
                    );
                  }}
                  input={
                    <MaskedInput mask={createNumberMask({ prefix: '£' })} />
                  }
                />
                {formik.touched.estateValue && formik.errors.estateValue && (
                  <Message
                    error
                    content={formik.errors.estateValue}
                    size="mini"
                  />
                )}
              </Form.Field>
            </Form.Group>

            <Form.Field>
              <Button type="submit" disabled={false}>
                Save
              </Button>
            </Form.Field>
          </Form>
        );
      }}
    </Formik>
  );
}

export default NoticeEditForm;
