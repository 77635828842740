import React from 'react';
import { Menu, Segment, Icon } from 'semantic-ui-react';
import MainPage from '../main/MainPage.jsx';
import SourcesTable from './SourcesTable.jsx';

const SourcesPage = props => {
  return (
    <MainPage title="Sources">
      <Menu attached="top">
        <Menu.Item
          as="a"
          name="New"
          onClick={() => props.history.push('/sources/add')}
        >
          <Icon name="file outline" />
          New
        </Menu.Item>
      </Menu>

      <Segment attached="bottom">
        <SourcesTable />
      </Segment>
    </MainPage>
  );
};

export default SourcesPage;
