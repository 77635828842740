import gql from 'graphql-tag';
import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import {
  Loader,
  Grid,
  Visibility,
  Sidebar,
  Menu,
  Segment,
} from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-light-svg-icons';
import MainPage from '../main/MainPage.jsx';
import ResourceCard from './ResourceCard.jsx';
import SearchBar from './SearchBar.jsx';

const LOAD_RATE = 20;

const RESOURCES = gql`
  query Resources($query: ResourcesQuery, $skip: Int, $limit: Int) {
    resources(query: $query, skip: $skip, limit: $limit) {
      _count
      resources {
        _id
        title
        notes
        authors {
          fname
          sname
        }
        geo {
          places
          counties
          countries
        }
        publisher
        published
        isbn
        issn
        location
        coverage {
          from
          to
        }
        topic
        id
      }
    }
  }
`;

function ResourcesPage() {
  const [searchBarVisible, setSearchBarVisible] = useState(false);
  const [selector, setSelector] = useState({});

  const { loading, data, fetchMore } = useQuery(RESOURCES, {
    variables: {
      query: {
        ...selector,
      },
      limit: LOAD_RATE,
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  function handleSearchButtonClick() {
    setSearchBarVisible(!searchBarVisible);
  };

  function handleSearchBarClose() {
    setSearchBarVisible(false);
  };

  function handleSearch(query) {
    setSelector(query);
  }

  if (loading) return <Loader active />;

  const {
    resources: { resources },
  } = data;

  return (
    <div>
      <Sidebar.Pushable as={MainPage} title="Library">
        <Sidebar.Pusher>
          <Menu attached="top">
            <Menu.Item as="a" name="Search" onClick={handleSearchButtonClick}>
              <FontAwesomeIcon icon={faSearch} style={{ marginRight: '5px' }} />
              Search
            </Menu.Item>
          </Menu>

          <Segment>
            <Visibility
              once={false}
              fireOnMount={false}
              onBottomVisible={() => {
                fetchMore({
                  variables: {
                    skip: resources.length,
                    limit: LOAD_RATE,
                  },
                  updateQuery: (previousResult, { fetchMoreResult }) => {
                    const updatedResources = {
                      ...previousResult,
                      resources: {
                        ...previousResult.resources,
                        resources: [
                          ...previousResult.resources.resources,
                          ...fetchMoreResult.resources.resources,
                        ],
                      },
                    };

                    return updatedResources;
                  },
                });
              }}
            >
              <Grid columns={1}>
                {resources.map(resource => (
                  <Grid.Row key={resource._id}>
                    <Grid.Column>
                      <ResourceCard resource={resource} />
                    </Grid.Column>
                  </Grid.Row>
                ))}
                <Grid.Row style={{ padding: '40px 0 40px 0' }}>
                  <Loader active={loading} />
                </Grid.Row>
              </Grid>
            </Visibility>
          </Segment>
        </Sidebar.Pusher>

        <SearchBar
          visible={searchBarVisible}
          onClose={handleSearchBarClose}
          onSearch={handleSearch}
        />
      </Sidebar.Pushable>
    </div>
  );
}

export default ResourcesPage;
