import React from 'react';
import { useHistory } from 'react-router-dom';
import { Menu, Segment, Icon } from 'semantic-ui-react';
import NoticeWorkingSetsTable from './NoticeWorkingSetsTable.jsx';

function NoticeWorkingSetsList() {
  const history = useHistory();

  return (
    <div>
      <Menu attached="top">
        <Menu.Item
          as="a"
          name="New"
          onClick={() => history.push('workingsets/add')}
        >
          <Icon name="plus" />
          Add
        </Menu.Item>
      </Menu>

      <Segment attached="bottom">
        <NoticeWorkingSetsTable />
      </Segment>
    </div>
  );
}

export default NoticeWorkingSetsList;
