import React from 'react';
import { Grid } from 'semantic-ui-react';
import MainPage from '../../main/MainPage.jsx';
import WorkingSetsAddForm from './WorkingSetsAddForm.jsx';

const WorkingSetsAddPage = () =>
  <MainPage title="Add Working Set">
    <Grid columns="equal">
      <Grid.Column />
      <Grid.Column width={10}>
        <WorkingSetsAddForm />
      </Grid.Column>
      <Grid.Column />
    </Grid>
  </MainPage>;

export default WorkingSetsAddPage;
