import React, { useState, useContext } from 'react';
import { Mutation } from '@apollo/react-components';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Table, Pagination, Icon, Loader, Confirm } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import DeletePopup from './DeletePopup';

const WORKING_SETS = gql`
  query workingSets(
    $searchTerm: String
    $skip: Int
    $limit: Int
    $sort: SortSpec
  ) {
    workingSets(
      searchTerm: $searchTerm
      skip: $skip
      limit: $limit
      sort: $sort
    ) {
      _count
      workingSets {
        _id
        name
      }
    }
  }
`;

const DELETE_WORKING_SET = gql`
  mutation deleteWorkingSet($_id: ObjectID!) {
    deleteWorkingSet(_id: $_id)
  }
`;

function WorkingSetsTable({ history }) {
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [workingSetId, setWorkingSetId] = useState(null);

  const {
    workingSetsQuickSearchTerm,
    workingSetsCurrentPage,
    workingSetsSortColumn,
    workingSetsSortDirection,
    workingSetsPageSize,
    setAppState,
  } = useContext(AppContext);

  const queryVars = {
    ...(workingSetsQuickSearchTerm
      ? { searchTerm: workingSetsQuickSearchTerm }
      : {}),
    limit: workingSetsPageSize,
    skip: workingSetsPageSize * workingSetsCurrentPage,
    sort: {
      column: workingSetsSortColumn,
      direction: workingSetsSortDirection,
    },
  };

  const { loading, data } = useQuery(WORKING_SETS, {
    variables: queryVars,
  });

  if (loading) {
    return (
      <div style={{ padding: '20px' }}>
        <Loader active />
      </div>
    );
  }

  const {
    workingSets: { _count, workingSets },
  } = data;

  return (
    <Table celled selectable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Name</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {workingSets.map(workingSet => (
          <DeletePopup
            onDelete={() => {
              setConfirmDeleteOpen(true);
              setWorkingSetId(workingSet._id);
            }}
            key={workingSet._id}
          >
            <Table.Row
              onClick={() => history.push(`/workingset/${workingSet.name}`)}
            >
              <Table.Cell>{workingSet.name}</Table.Cell>
            </Table.Row>
          </DeletePopup>
        ))}
      </Table.Body>

      <Table.Footer>
        <Table.Row>
          <Table.HeaderCell className="center aligned">
            <Pagination
              activePage={workingSetsCurrentPage + 1}
              totalPages={Math.ceil(_count / workingSetsPageSize)}
              onPageChange={(event, data) => {
                setAppState('workingSetsCurrentPage', data.activePage - 1);
              }}
              firstItem={{ content: <Icon name="double left angle" /> }}
              prevItem={{ content: <Icon name="left angle" /> }}
              nextItem={{ content: <Icon name="right angle" /> }}
              lastItem={{ content: <Icon name="double right angle" /> }}
            />
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>

      <Mutation mutation={DELETE_WORKING_SET}>
        {mutate => (
          <Confirm
            open={confirmDeleteOpen}
            onCancel={() => {
              setConfirmDeleteOpen(false);
              setWorkingSetId(null);
            }}
            onConfirm={() => {
              mutate({
                variables: { _id: workingSetId },
                refetchQueries: [{ query: WORKING_SETS, variables: queryVars }],
              });
              setConfirmDeleteOpen(false);
            }}
          />
        )}
      </Mutation>
    </Table>
  );
}

export default withRouter(WorkingSetsTable);
