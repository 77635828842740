import React from 'react';
import { Grid } from 'semantic-ui-react';
import MainPage from '../../main/MainPage.jsx';
import NoticesCreateWorkingSetForm from './NoticesCreateWorkingSetForm.jsx';

function NoticesCreateWorkingSetPage() {
  return (
    <MainPage title="Create Working Set">
      <Grid columns="equal">
        <Grid.Column />
        <Grid.Column width={10}>
          <NoticesCreateWorkingSetForm />
        </Grid.Column>
        <Grid.Column />
      </Grid>
    </MainPage>
  );
}

export default NoticesCreateWorkingSetPage;
