import React from 'react';
import { Header, Segment, Grid } from 'semantic-ui-react';
import NoticeGrantEditForm from './NoticeGrantEditForm.jsx';

export default () => (
  <div>
    <Header as="h5" attached="top">
      Edit Grant
    </Header>
    <Segment attached>
      <Grid>
        <Grid.Column />
        <Grid.Column width={15}>
          <NoticeGrantEditForm />
        </Grid.Column>
      </Grid>
    </Segment>
  </div>
);
