import React from 'react';
import { Grid } from 'semantic-ui-react';
import MainPage from '../../main/MainPage.jsx';
import SolicitorsAddForm from './SolicitorsAddForm.jsx';

export default () => (
  <MainPage title="Add Solicitor">
    <Grid columns="equal">
      <Grid.Column />
      <Grid.Column width={10}>
        <SolicitorsAddForm />
      </Grid.Column>
      <Grid.Column />
    </Grid>
  </MainPage>
);
