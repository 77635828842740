import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Segment } from 'semantic-ui-react';

import NoticeWorkingSetsList from './list/NoticeWorkingSetsList.jsx';
import NoticeWorkingSetsAdd from './add/NoticeWorkingSetsAdd.jsx';

const NoticeWorkingSets = ({ showWorkingSet }) => (
  <Segment basic>
    <Switch>
      <Route
        path={'/notice/:reference/workingsets/add'}
        render={() => (
          <NoticeWorkingSetsAdd />
        )}
      />

      <Route
        path={'/notice/:reference/workingsets'}
        render={() => (
          <NoticeWorkingSetsList
            showWorkingSet={showWorkingSet}
          />
        )}
      />
    </Switch>
  </Segment>
);

export default NoticeWorkingSets;
