import React, { useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Table, Pagination, Icon, Loader } from 'semantic-ui-react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../AppContext';

const GRANTS = gql`
  query Grants($query: GrantsQuery, $skip: Int, $limit: Int, $sort: SortSpec) {
    grants(query: $query, skip: $skip, limit: $limit, sort: $sort) {
      _count
      grants {
        _id
        noticereference
        extractiondate
        solicitor
        familyname
        firstnames
      }
    }
  }
`;

const EXTRACTION_DATE = 'extractiondate';
const SOLICITOR = 'solicitor';
const FAMILY_NAME = 'familyname';
const FIRST_NAMES = 'firstnames';

function toggleSortDirection(sortDirection) {
  return sortDirection === 'ascending' ? 'descending' : 'ascending';
}

function GrantsTable() {
  const history = useHistory();

  const {
    setAppState,
    grantsQuery,
    grantsPageSize,
    grantsSortColumn,
    grantsSortDirection,
    grantsCurrentPage,
  } = useContext(AppContext);

  const { loading, data } = useQuery(GRANTS, {
    variables: {
      query: grantsQuery,
      limit: grantsPageSize,
      skip: grantsPageSize * grantsCurrentPage,
      sort: {
        column: grantsSortColumn,
        direction: grantsSortDirection,
      },
    },
  });

  if (loading) {
    return (
      <div style={{ padding: '20px' }}>
        <Loader active />
      </div>
    );
  }

  const {
    grants: { _count, grants },
  } = data;

  return (
    <Table sortable celled selectable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell
            sorted={
              grantsSortColumn === EXTRACTION_DATE ? grantsSortDirection : null
            }
            onClick={() => {
              setAppState('grantsSortColumn', EXTRACTION_DATE);
              setAppState(
                'grantsSortDirection',
                grantsSortColumn !== EXTRACTION_DATE
                  ? 'ascending'
                  : toggleSortDirection(grantsSortDirection)
              );
            }}
          >
            Extraction Date
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={grantsSortColumn === SOLICITOR ? grantsSortDirection : null}
            onClick={() => {
              setAppState('grantsSortColumn', SOLICITOR);
              setAppState(
                'grantsSortDirection',
                grantsSortColumn !== SOLICITOR
                  ? 'ascending'
                  : toggleSortDirection(grantsSortDirection)
              );
            }}
          >
            Solicitor
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={
              grantsSortColumn === FAMILY_NAME ? grantsSortDirection : null
            }
            onClick={() => {
              setAppState('grantsSortColumn', FAMILY_NAME);
              setAppState(
                'grantsSortDirection',
                grantsSortColumn !== FAMILY_NAME
                  ? 'ascending'
                  : toggleSortDirection(grantsSortDirection)
              );
            }}
          >
            Family Name
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={
              grantsSortColumn === FIRST_NAMES ? grantsSortDirection : null
            }
            onClick={() => {
              setAppState('grantsSortColumn', FIRST_NAMES);
              setAppState(
                'grantsSortDirection',
                grantsSortColumn !== FIRST_NAMES
                  ? 'ascending'
                  : toggleSortDirection(grantsSortDirection)
              );
            }}
          >
            First Names
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {grants.map(grant => (
          <Table.Row
            key={grant._id}
            onClick={() =>
              history.push(`/notice/${grant.noticereference}/grants`)
            }
          >
            <Table.Cell>
              {grant.extractiondate
                ? moment(grant.extractiondate).format('DD/MM/YYYY')
                : 'n/a'}
            </Table.Cell>
            <Table.Cell>{grant.solicitor}</Table.Cell>
            <Table.Cell>{grant.familyname}</Table.Cell>
            <Table.Cell>{grant.firstnames}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
      <Table.Footer>
        <Table.Row>
          <Table.HeaderCell colSpan="4" className="center aligned">
            <Pagination
              activePage={grantsCurrentPage + 1}
              totalPages={Math.ceil(_count / (grantsPageSize || _count))}
              onPageChange={(event, data) =>
                setAppState('grantsCurrentPage', data.activePage - 1)
              }
              firstItem={{ content: <Icon name="double left angle" /> }}
              prevItem={{ content: <Icon name="left angle" /> }}
              nextItem={{ content: <Icon name="right angle" /> }}
              lastItem={{ content: <Icon name="double right angle" /> }}
            />
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    </Table>
  );
}

export default GrantsTable;
