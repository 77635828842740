import React from 'react';
import gql from 'graphql-tag';
import { Mutation } from '@apollo/react-components';

const CREATE_NOTICE = gql`
  mutation createNotice($input: NoticeInput!) {
    createNotice(input: $input) {
      _id
      noticedate
      source
      informant
      sex
      reference
      familyname
      familynameakas
      maidenname
      firstnames
      firstnameakas
      livedat
      diedat
      diedon
      estatevalue
    }
  }
`;

export default Component => props => (
  <Mutation mutation={CREATE_NOTICE}>
    {mutate => (
      <Component
        {...props}
        createNotice={notice =>
          mutate({
            variables: {
              input: notice,
            },
          })
        }
      />
    )}
  </Mutation>
);
