import React from 'react';
import { Grid } from 'semantic-ui-react';
import MainPage from '../../main/MainPage.jsx';
import UsersAddForm from './UsersAddForm.jsx';

const handleSubmit = (/* values */) => {};

const UsersAddPage = () => (
  <MainPage title="Add User">
    <Grid columns="equal">
      <Grid.Column />
      <Grid.Column width={10}>
        <UsersAddForm onSubmit={handleSubmit} />
      </Grid.Column>
      <Grid.Column />
    </Grid>
  </MainPage>
);

export default UsersAddPage;
