import React from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { Formik } from 'formik';
import { Form, Input, Dropdown, Button, Loader } from 'semantic-ui-react';
import accounting from 'accounting';
import 'react-datepicker/dist/react-datepicker.css';
import { withRouter } from 'react-router-dom';
import get from 'lodash.get';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import styled from 'styled-components';
import * as yup from 'yup';
import ExtractionDateDatePicker from './ExtractionDateDatePicker';
import { GRANT, UPDATE_GRANT, SOLICITORS } from './gql';

const RequiredLabel = styled.label`
  ::after {
    margin: -0.2em 0 0 0.2em !important;
    content: '*';
    color: #db2828 !important;
  }
`;

const ErrorLabel = styled.span`
  color: #db2828;
  margin-left: 1em;
`;

function NoticeGrantEditForm(props) {
  const {
    history: { push },
    match: {
      params: { grant: grantId },
    },
  } = props;

  const { loading: grantLoading, data: grantData } = useQuery(GRANT, {
    variables: {
      _id: grantId,
    },
  });

  const { loading: solicitorsLoading, data: solicitorsData } = useQuery(
    SOLICITORS
  );

  const [updateGrant] = useMutation(UPDATE_GRANT);

  if (grantLoading || solicitorsLoading) {
    return <Loader active />;
  }

  const {
    solicitors: { solicitors },
  } = solicitorsData;

  const { grant } = grantData;

  return (
    <Formik
      enableReinitialize
      initialValues={{
        extractionDate: new Date(get(grant, 'extractiondate')),
        grantee: get(grant, 'grantedto', ''),
        solicitor: get(grant, 'solicitor', ''),
        value: get(grant, 'value', 0),
      }}
      validationSchema={yup.object().shape({
        extractionDate: yup.date().required(),
        grantee: yup.string().required(),
        value: yup
          .number()
          .positive()
          .integer(),
      })}
      onSubmit={async values => {
        const { __typename, ...rest } = grant;

        const updatedGrant = {
          ...rest,
          extractiondate: values.extractionDate,
          grantedto: values.grantee,
          solicitor: values.solicitor,
          value: `${values.value}`,
        };

        await updateGrant({ variables: { input: updatedGrant } });

        push(`/notice/${grant.noticereference}/grants`);
      }}
    >
      {formik => {
        return (
          <Form onSubmit={formik.handleSubmit} noValidate>
            <Form.Group inline>
              <RequiredLabel
                required
                style={{ width: '100px' }}
                htmlFor="extractionDate"
              >
                Extraction date
              </RequiredLabel>

              <ExtractionDateDatePicker
                value={formik.values.extractionDate}
                onChange={formik.setFieldValue}
                onBlur={formik.setFieldTouched}
                error={formik.errors.extractionDate}
                touched={formik.touched.extractionDate}
              />

              {formik.errors.extractionDate && (
                <ErrorLabel>A valid date must be specified</ErrorLabel>
              )}
            </Form.Group>

            <Form.Group inline>
              <RequiredLabel style={{ width: '100px' }} htmlFor="grantee">
                Granted to
              </RequiredLabel>

              <Input
                name="grantee"
                style={{ width: '300px' }}
                defaultValue={formik.values.grantee}
                onChange={(e, { value }) => {
                  formik.setFieldValue('grantee', value);
                  formik.setFieldTouched('grantee');
                }}
                onBlur={formik.handleBlur}
              />
              {formik.errors.grantee && (
                <ErrorLabel>Grantee must be specified</ErrorLabel>
              )}
            </Form.Group>

            <Form.Group inline>
              <label style={{ width: '100px' }} htmlFor="solicitor">
                Solicitor
              </label>

              <Dropdown
                name="solicitor"
                placeholder="Solicitor"
                search
                selection
                onChange={(e, { value }) => {
                  formik.setFieldValue('solicitor', value);
                  formik.setFieldTouched('solicitor');
                }}
                onBlur={formik.handleBlur}
                value={formik.values.solicitor}
                options={solicitors.map((solicitor, i) => ({
                  key: i,
                  text: solicitor.name,
                  value: solicitor.name,
                }))}
              />
            </Form.Group>

            <Form.Group inline>
              <label style={{ width: '100px' }} htmlFor="value">
                Value
              </label>

              <MaskedInput
                name="value"
                style={{ width: '300px' }}
                value={formik.values.value}
                mask={createNumberMask({ prefix: '£' })}
                onChange={e => {
                  formik.setFieldValue(
                    'value',
                    accounting.unformat(e.target.value)
                  );
                  formik.setFieldTouched('value');
                }}
                onBlur={formik.handleBlur}
              />
            </Form.Group>

            <Form.Group inline>
              <Button
                type="submit"
                disabled={formik.isSubmitting || !formik.isValid}
              >
                Update
              </Button>
            </Form.Group>
          </Form>
        );
      }}
    </Formik>
  );
}

export default withRouter(NoticeGrantEditForm);
