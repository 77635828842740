import React, { useEffect, useState } from 'react';
import Uppy from '@uppy/core';
import AwsS3Multipart from '@uppy/aws-s3-multipart';
import { DashboardModal } from '@uppy/react';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import { useAuth0 } from '../../react-auth0-spa';

export default function Files() {
  const [uppy, setUppy] = useState(null);
  const { loading, user } = useAuth0();

  useEffect(() => {
    if (loading) return;

    const tempUppy = Uppy({
      meta: {
        username: 'John',
        noticeId: '',
      },
    });

    tempUppy.use(AwsS3Multipart, {
      limit: 4,
      companionUrl: process.env.REACT_APP_UPPY_COMPANION_URL,
    });

    setUppy(tempUppy);
  }, [user, loading]);

  if (!uppy) return null;

  return (
    <DashboardModal
      uppy={uppy}
      metaFields={[
        {
          id: 'description',
          name: 'Description',
          placeholder: 'file description',
        },
      ]}
    />
  );
}
