import React, { useState, useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { withRouter } from 'react-router-dom';
import gql from 'graphql-tag';
import { Form, Button, Input, Dropdown, Loader } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import pickBy from 'lodash.pickby';
import 'react-datepicker/dist/react-datepicker.css';
import { AppContext } from '../../../AppContext';

const SOLICITORS = gql`
  query solicitors($skip: Int, $limit: Int) {
    solicitors(skip: $skip, limit: $limit) {
      _count
      solicitors {
        _id
        name
      }
    }
  }
`;

function GrantsSearchForm({ history }) {
  const [extractionDateFrom, setExtractionDateFrom] = useState(null);
  const [extractionDateTo, setExtractionDateTo] = useState(null);
  const [grantedTo, setGrantedTo] = useState('');
  const [solicitor, setSolicitor] = useState('');
  const { setAppState } = useContext(AppContext);
  const { loading, data } = useQuery(SOLICITORS);

  if (loading) return <Loader active />;

  const {
    solicitors: { solicitors },
  } = data;

  function handleSubmit(e) {
    const query = pickBy({
      extractionDateFrom,
      extractionDateTo,
      grantedTo,
      solicitor,
    });

    setAppState('grantsQuery', query);
    history.push('/grants');
    e.preventDefault();
  }

  return (
    <Form onSubmit={handleSubmit} noValidate>
      <Form.Group inline>
        <label style={{ width: '100px' }}>Extracted between</label>
        <div style={{ width: '150px' }}>
          <DatePicker
            dateFormat="dd/MM/yyyy"
            name="extractionDateFrom"
            selected={extractionDateFrom}
            onChange={setExtractionDateFrom}
            onBlur={() => setExtractionDateTo(extractionDateFrom)}
          />
        </div>

        <label style={{ marginLeft: '10px', marginRight: '10px' }}>and</label>
        <div style={{ width: '150px' }}>
          <DatePicker
            dateFormat="dd/MM/yyyy"
            name="extractionDateTo"
            selected={extractionDateTo}
            onChange={setExtractionDateTo}
          />
        </div>
      </Form.Group>

      <Form.Group inline>
        <label style={{ width: '100px' }}>Granted to</label>
        <Input
          name="grantedTo"
          style={{ width: '300px' }}
          onChange={(e, { value }) => setGrantedTo(value)}
        />
      </Form.Group>

      <Form.Group inline>
        <label style={{ width: '100px' }}>Solicitor</label>
        <Dropdown
          name="solicitor"
          placeholder="Select solicitor"
          search
          selection
          options={solicitors.map(s => ({
            key: s._id,
            value: s.name,
            text: s.name,
          }))}
          onChange={(e, { value }) => setSolicitor(value)}
        />
      </Form.Group>

      <Button type="submit">Search</Button>
    </Form>
  );
}

export default withRouter(GrantsSearchForm);
