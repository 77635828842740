import React, { Fragment } from 'react';
import { Loader, Grid } from 'semantic-ui-react';
import chunk from 'lodash.chunk';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import MainPage from '../main/MainPage.jsx';
import ContactCard from './ContactCard.jsx';

const CONTACTS = gql`
  query Contacts {
    contacts {
      _id
      firstname
      lastname
      phoneno
      extno
      mobno
      mobext
      email
      office
    }
  }
`;

function ContactsPage() {
  const { loading, data } = useQuery(CONTACTS);

  if (loading) {
    return (
      <div style={{ padding: '20px' }}>
        <Loader active />
      </div>
    );
  }

  const { contacts } = data;

  return (
    <MainPage title="Contacts">
      <Fragment>
        <Grid columns={2} divided>
          {chunk(contacts, 2).map(contactsChunk => {
            return (
              <Grid.Row key={contactsChunk[0]._id}>
                <Grid.Column>
                  <ContactCard contact={contactsChunk[0]} />
                </Grid.Column>
                <Grid.Column>
                  <ContactCard contact={contactsChunk[1]} />
                </Grid.Column>
              </Grid.Row>
            );
          })}
        </Grid>
      </Fragment>
      );
    </MainPage>
  );
}

export default ContactsPage;
