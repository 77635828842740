import React, { useContext } from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import gql from 'graphql-tag';
import { Mutation } from '@apollo/react-components';
import { Form, Button } from 'semantic-ui-react';
import { Field, reduxForm } from 'redux-form';
import Validator from 'validatorjs';
import { renderInput } from '../../common/forms';
import { AppContext } from '../../../AppContext';

const CREATE_WORKING_SET = gql`
  mutation createWorkingSet($name: String!) {
    createWorkingSet(name: $name) {
      _id
    }
  }
`;

const WORKING_SETS = gql`
  query workingSets(
    $searchTerm: String
    $skip: Int
    $limit: Int
    $sort: SortSpec
  ) {
    workingSets(
      searchTerm: $searchTerm
      skip: $skip
      limit: $limit
      sort: $sort
    ) {
      _count
      workingSets {
        _id
        name
      }
    }
  }
`;

function WorkingSetsAddForm(props) {
  const {
    workingSetsCurrentPage,
    workingSetsSortColumn,
    workingSetsSortDirection,
    workingSetsPageSize,
  } = useContext(AppContext);

  const { handleSubmit, pristine, valid, history } = props;

  return (
    <Mutation mutation={CREATE_WORKING_SET}>
      {createWorkingSet => {
        async function submit({ name }) {
          const queryVars = {
            limit: workingSetsPageSize,
            skip: workingSetsPageSize * workingSetsCurrentPage,
            sort: {
              column: workingSetsSortColumn,
              direction: workingSetsSortDirection,
            },
          };

          await createWorkingSet({
            variables: {
              name,
            },
            refetchQueries: [
              {
                query: WORKING_SETS,
                variables: queryVars,
              },
            ],
          });

          history.push('/workingsets');
        }

        return (
          <Form onSubmit={handleSubmit(submit)} noValidate>
            <Form.Group inline>
              <Field
                required
                name="name"
                label="Name"
                component={renderInput}
              />
            </Form.Group>

            <Button type="submit" disabled={!valid || pristine}>
              Submit
            </Button>
          </Form>
        );
      }}
    </Mutation>
  );
}

const validate = data => {
  const rules = {
    name: 'required|min:3',
  };

  const messages = {
    'required.name': 'Working Set name is required.',
    'min.name': 'Working Set name must be at least 3 characters.',
  };

  const validator = new Validator(data, rules, messages);

  return validator.passes() ? {} : validator.errors.all();
};

export default compose(
  withRouter,
  reduxForm({
    form: 'WorkingSetsAddForm',
    validate,
    initialValues: {
      name: '',
    },
  }),
)(WorkingSetsAddForm);
