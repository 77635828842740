import React from 'react';
import { Menu, Segment, Icon } from 'semantic-ui-react';
import MainPage from '../main/MainPage.jsx';
import SolicitorsTable from './SolicitorsTable.jsx';

const SolicitorsPage = props =>
  <MainPage title="Solicitors">
    <Menu attached="top">
      <Menu.Item
        as="a"
        name="New"
        onClick={() => props.history.push('/solicitors/add')}
      >
        <Icon name="file outline" />
        New
      </Menu.Item>
    </Menu>

    <Segment attached="bottom">
      <SolicitorsTable {...props} />
    </Segment>
  </MainPage>;

export default SolicitorsPage;
