import ApolloClient from 'apollo-boost';
import * as Sentry from '@sentry/browser';
import { useAuth0 } from './react-auth0-spa';

export default function (getTokenSilently) {
  const { logout } = useAuth0();

  return new ApolloClient({
    uri: process.env.REACT_APP_GRAPHQL_SERVER,
    request: async (operation) => {
      const token = await getTokenSilently();

      operation.setContext({
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    },
    onError: ({ graphQLErrors, networkError, response }) => {
      if (graphQLErrors && graphQLErrors.length > 0) {
        graphQLErrors.map(({ message }) => Sentry.captureMessage(message));
      }

      if (networkError) {
        if ((networkError).error === 'login_required') {
          logout({ returnTo: process.env.REACT_APP_HOME_URL });
        } else {
          Sentry.captureException(networkError);
        }
      }

      if (response) {
        response.errors = [];
      }
    },
  });
}
