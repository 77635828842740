import React, { useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { withRouter } from 'react-router-dom';
import gql from 'graphql-tag';
import { Table, Pagination, Icon, Loader } from 'semantic-ui-react';
import { AppContext } from '../../AppContext';

export const SOLICITORS = gql`
  query solicitors($skip: Int, $limit: Int) {
    solicitors(skip: $skip, limit: $limit) {
      _count
      solicitors {
        _id
        name
      }
    }
  }
`;

function SolicitorsTable({ history }) {
  const { setAppState, solicitorsPageSize, solicitorsCurrentPage } = useContext(
    AppContext
  );

  const { loading, data } = useQuery(SOLICITORS, {
    variables: {
      limit: solicitorsPageSize,
      skip: solicitorsPageSize * solicitorsCurrentPage,
    },
  });

  if (loading) {
    return (
      <div style={{ padding: '20px' }}>
        <Loader active />
      </div>
    );
  }

  const {
    solicitors: { _count, solicitors },
  } = data;
  return (
    <Table celled selectable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Name</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {solicitors.map(solicitor => (
          <Table.Row
            key={solicitor._id}
            onClick={() => {
              setAppState('grantsQuery', { solicitor: solicitor.name });
              setAppState('grantsCurrentPage', 0);
              setAppState('grantsPageSize', 15);
              history.push('/grants');
            }}
          >
            <Table.Cell>{solicitor.name}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>

      <Table.Footer>
        <Table.Row>
          <Table.HeaderCell className="center aligned">
            <Pagination
              activePage={solicitorsCurrentPage + 1}
              totalPages={Math.ceil(_count / solicitorsPageSize)}
              onPageChange={(event, pageData) => {
                setAppState('solicitorsCurrentPage', pageData.activePage - 1);
              }}
              firstItem={{ content: <Icon name="double left angle" /> }}
              prevItem={{ content: <Icon name="left angle" /> }}
              nextItem={{ content: <Icon name="right angle" /> }}
              lastItem={{ content: <Icon name="double right angle" /> }}
            />
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    </Table>
  );
}

export default withRouter(SolicitorsTable);
