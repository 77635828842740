import React, { useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Loader } from 'semantic-ui-react';
import cogoToast from 'cogo-toast';
import * as Sentry from '@sentry/browser';
import { AppContext } from '../../../AppContext';
import NoticesTable from './NoticesTable.jsx';
import { NOTICES } from './gql';

function DefaultNoticesTable() {
  const {
    noticesCurrentPage,
    noticesSortColumn,
    noticesSortDirection,
    noticesPageSize,
    noticesSearchTerms,
  } = useContext(AppContext);

  const { loading, data, error } = useQuery(NOTICES, {
    skip: !noticesSearchTerms,
    fetchPolicy: 'cache-and-network',
    variables: {
      query: {
        ...noticesSearchTerms,
      },
      limit: noticesPageSize,
      skip: noticesPageSize * noticesCurrentPage,
      sort: {
        column: noticesSortColumn,
        direction: noticesSortDirection,
      },
    },
  });

  if (error) {
    cogoToast.error('An unknown error occurred.');
    Sentry.captureException(error);
    return null;
  }

  if (!noticesSearchTerms) return null;

  if (loading) {
    return (
      <div style={{ padding: '20px' }}>
        <Loader active />
      </div>
    );
  }

  const { notices } = data;

  return (
    <NoticesTable
      loading={loading}
      notices={notices.notices}
      count={notices._count}
    />
  );
}

export default DefaultNoticesTable;
