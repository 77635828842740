import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Table, Pagination, Icon, Loader } from 'semantic-ui-react';
import { AppContext } from '../../AppContext';

export const SOURCES = gql`
  query Sources($skip: Int, $limit: Int) {
    sources2(skip: $skip, limit: $limit) {
      _count
      sources {
        _id
        name
        lowerCaseName
      }
    }
  }
`;

function SourcesTable({ history }) {
  const { sourcesPageSize, sourcesCurrentPage, setAppState } = useContext(
    AppContext
  );

  const { loading, data } = useQuery(SOURCES, {
    variables: {
      skip: sourcesPageSize * sourcesCurrentPage,
      limit: sourcesPageSize,
    },
  });

  if (loading) return <Loader active={loading} />;

  const {
    sources2: { sources, _count },
  } = data;

  return (
    <Table celled selectable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Name</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {sources.map(source => (
          <Table.Row
            key={source._id}
            onClick={() => {
              setAppState('noticesSearchTerms', {
                source: source.name,
              });

              setAppState('noticesCurrentPage', 0);
              history.push('/notices');
            }}
          >
            <Table.Cell>{source.name}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>

      <Table.Footer>
        <Table.Row>
          <Table.HeaderCell className="center aligned">
            <Pagination
              activePage={sourcesCurrentPage + 1}
              totalPages={Math.ceil(_count / sourcesPageSize)}
              onPageChange={(event, pageData) =>
                setAppState('sourcesCurrentPage', pageData.activePage - 1)
              }
              firstItem={{ content: <Icon name="double left angle" /> }}
              prevItem={{ content: <Icon name="left angle" /> }}
              nextItem={{ content: <Icon name="right angle" /> }}
              lastItem={{ content: <Icon name="double right angle" /> }}
            />
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    </Table>
  );
}

export default withRouter(SourcesTable);
