import React from 'react';
import {
  Header,
  Grid,
  Container,
  Loader,
  Segment,
  Dimmer,
  Image,
} from 'semantic-ui-react';
import MainMenu from '../main-menu/MainMenu.jsx';

function MainPage({ loading, title, children }) {
  return (
    <Grid style={{ height: '100vh', margin: 0 }}>
      <Grid.Column
        width={3}
        className="sideBar"
      >
        <MainMenu visible />
      </Grid.Column>
      <Grid.Column width={13}>
        {loading ? (
          <Segment>
            <Dimmer active inverted>
              <Loader size="large">Loading</Loader>
            </Dimmer>
            <Image src="/paragraph.png" />
          </Segment>
        ) : (
          <Container style={{ padding: '20px 20px 0 20px'}}>
            <Header as="h2">{title}</Header>
            {children}
          </Container>
        )}
      </Grid.Column>
    </Grid>
  );
}

export default MainPage;
