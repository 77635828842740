import React from 'react';
import DatePicker from 'react-datepicker';

export default class NoticeDateDatePicker extends React.Component {
  handleChange = value => {
    this.props.onChange('noticeDate', value);
  };

  handleBlur = () => {
    this.props.onBlur('noticeDate', true);
  };

  render() {
    return (
      <React.Fragment>
        <DatePicker
          style={{ width: '150px', verticalAlign: 'middle' }}
          placeholderText="DD/MM/YYYY"
          dateFormat="dd/MM/yyyy"
          selected={this.props.value}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
        />

        {!!this.props.error && this.props.touched && (
          <div style={{ color: 'red', marginTop: '.5rem' }}>
            {this.props.error}
          </div>
        )}
      </React.Fragment>
    );
  }
}
