import React, { useEffect, useState } from 'react';
import { Modal, Button, Form } from 'semantic-ui-react';

export default function RenameModal(props) {
  const [name, setName] = useState(props.currentName);

  useEffect(() => {
    setName(props.currentName);
  }, [props.currentName]);

  return (
    <Modal open={props.open} size="mini">
      <Modal.Header>Display Name</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Input
            fluid
            value={name}
            onChange={(e, { value }) => setName(value)}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          primary
          content="Save"
          onClick={e => {
            props.onSave({ displayName: name });
            e.preventDefault();
          }}
        />
      </Modal.Actions>
    </Modal>
  );
}
