import gql from 'graphql-tag';

export const NOTES = gql`
  query Notice($reference: String!) {
    notice(reference: $reference) {
      _id
      notes {
        id
        date
        user
        text
        private
      }
    }
  }
`;

export const DELETE_NOTE = gql`
  mutation deleteNote($reference: String!, $input: NoteInput!) {
    deleteNote(reference: $reference, input: $input)
  }
`;

export const UPDATE_NOTE = gql`
  mutation updateNote($reference: String!, $input: NoteInput!) {
    updateNote(reference: $reference, input: $input) {
      id
      date
      user
      text
      private
    }
  }
`;

export const CREATE_NOTE = gql`
  mutation CreateNote($reference: String!, $input: NoteInput!) {
    createNote(reference: $reference, input: $input) {
      id
      date
      user
      text
      private
    }
  }
`;

