import React, { useContext } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { Table, Pagination, Icon, Loader } from 'semantic-ui-react';
import moment from 'moment';
import accounting from 'accounting';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import WorkingSetNotes from './WorkingSetNotes.jsx';
import { AppContext } from '../../AppContext';

const SOURCE = 'source';
const REFERENCE = 'reference';
const NOTICE_DATE = 'noticedate';
const DIED_ON = 'diedon';
const FAMILY_NAME = 'familyname';
const FIRST_NAMES = 'firstnames';
const ESTATE_VALUE = 'estatevalue';

const NOTICES = gql`
  query Notices(
    $query: NoticesQuery
    $skip: Int
    $limit: Int
    $sort: SortSpec
  ) {
    notices(query: $query, skip: $skip, limit: $limit, sort: $sort) {
      _count
      notices {
        _id
        noticedate
        diedon
        familyname
        firstnames
        estatevalue
        reference
        source
        notes {
          id
          text
          date
          user
        }
      }
    }
  }
`;

function toggleSortDirection(sortDirection) {
  return sortDirection === 'ascending' ? 'descending' : 'ascending';
}

function sortBy(
  sortColumn,
  currentSortColumn,
  currentSortDirection,
  setAppState
) {
  setAppState('workingSetSortColumn', sortColumn);
  setAppState(
    'workingSetSortDirection',
    currentSortColumn !== sortColumn
      ? 'ascending'
      : toggleSortDirection(currentSortDirection)
  );
}

function WorkingSetTable({ match, history }) {
  const {
    setAppState,
    workingSetCurrentPage,
    workingSetSortColumn,
    workingSetSortDirection,
    workingSetPageSize,
  } = useContext(AppContext);

  const { loading, data } = useQuery(NOTICES, {
    fetchPolicy: 'cache-and-network',
    variables: {
      query: {
        workingSet: match.params.name,
      },
      skip: workingSetPageSize * workingSetCurrentPage,
      limit: workingSetPageSize,
      sort: {
        column: workingSetSortColumn,
        direction: workingSetSortDirection,
      },
    },
  });

  if (loading) return <Loader active={loading} />;

  const {
    notices: { notices: workingSet, _count },
  } = data;

  return (
    <Table sortable celled selectable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell
            sorted={
              workingSetSortColumn === SOURCE ? workingSetSortDirection : null
            }
            onClick={() =>
              sortBy(
                SOURCE,
                workingSetSortColumn,
                workingSetSortDirection,
                setAppState
              )
            }
          >
            Source
          </Table.HeaderCell>

          <Table.HeaderCell
            sorted={
              workingSetSortColumn === REFERENCE
                ? workingSetSortDirection
                : null
            }
            onClick={() =>
              sortBy(
                REFERENCE,
                workingSetSortColumn,
                workingSetSortDirection,
                setAppState
              )
            }
          >
            Reference
          </Table.HeaderCell>

          <Table.HeaderCell
            sorted={
              workingSetSortColumn === NOTICE_DATE
                ? workingSetSortDirection
                : null
            }
            onClick={() =>
              sortBy(
                NOTICE_DATE,
                workingSetSortColumn,
                workingSetSortDirection,
                setAppState
              )
            }
          >
            Notice Date
          </Table.HeaderCell>

          <Table.HeaderCell
            sorted={
              workingSetSortColumn === DIED_ON ? workingSetSortDirection : null
            }
            onClick={() =>
              sortBy(
                DIED_ON,
                workingSetSortColumn,
                workingSetSortDirection,
                setAppState
              )
            }
          >
            Died
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={
              workingSetSortColumn === FAMILY_NAME
                ? workingSetSortDirection
                : null
            }
            onClick={() =>
              sortBy(
                FAMILY_NAME,
                workingSetSortColumn,
                workingSetSortDirection,
                setAppState
              )
            }
          >
            Family name
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={
              workingSetSortColumn === FIRST_NAMES
                ? workingSetSortDirection
                : null
            }
            onClick={() =>
              sortBy(
                FIRST_NAMES,
                workingSetSortColumn,
                workingSetSortDirection,
                setAppState
              )
            }
          >
            First names
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={
              workingSetSortColumn === ESTATE_VALUE
                ? workingSetSortDirection
                : null
            }
            onClick={() =>
              sortBy(
                ESTATE_VALUE,
                workingSetSortColumn,
                workingSetSortDirection,
                setAppState
              )
            }
          >
            Value
          </Table.HeaderCell>
          <Table.HeaderCell>Notes</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {workingSet.map(notice => (
          <Table.Row
            key={notice._id}
            onClick={() => history.push(`/notice/${notice.reference}`)}
          >
            <Table.Cell>{notice.source}</Table.Cell>
            <Table.Cell>{notice.reference}</Table.Cell>
            <Table.Cell>
              {moment(notice.noticedate).format('DD/MM/YYYY')}
            </Table.Cell>
            <Table.Cell>
              {moment(notice.diedon).format('DD/MM/YYYY')}
            </Table.Cell>
            <Table.Cell>{notice.familyname}</Table.Cell>
            <Table.Cell>{notice.firstnames}</Table.Cell>
            <Table.Cell>
              {accounting.formatMoney(notice.estatevalue, {
                symbol: '£',
                precision: 0,
                format: {
                  zero: '%s  --',
                },
              })}
            </Table.Cell>
            <Table.Cell>
              <WorkingSetNotes
                notes={notice.notes}
                showingAll={!workingSetPageSize}
              />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>

      <Table.Footer>
        <Table.Row>
          <Table.HeaderCell colSpan="8" className="center aligned">
            {workingSetPageSize ? (
              <Pagination
                activePage={workingSetCurrentPage + 1}
                totalPages={Math.ceil(_count / workingSetPageSize)}
                onPageChange={(event, pageData) => {
                  setAppState('workingSetCurrentPage', pageData.activePage - 1);
                }}
                firstItem={{ content: <Icon name="double left angle" /> }}
                prevItem={{ content: <Icon name="left angle" /> }}
                nextItem={{ content: <Icon name="right angle" /> }}
                lastItem={{ content: <Icon name="double right angle" /> }}
              />
            ) : null}
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    </Table>
  );
}

export default compose(withRouter)(WorkingSetTable);
