import React, { useContext } from 'react';
import { Table, Pagination, Icon, Loader } from 'semantic-ui-react';
import moment from 'moment';
import accounting from 'accounting';
import pickBy from 'lodash.pickby';
import isEmpty from 'lodash.isempty';
import { withRouter } from 'react-router-dom';
import { AppContext } from '../../../AppContext';

const NOTICE_DATE = 'noticedate';
const DIED_ON = 'diedon';
const FAMILY_NAME = 'familyname';
const FIRST_NAMES = 'firstnames';
const VALUE = 'estatevalue';

function toggleSortDirection(sortDirection) {
  return sortDirection === 'ascending' ? 'descending' : 'ascending';
}

function sortBy(
  sortColumn,
  currentSortColumn,
  currentSortDirection,
  setAppState
) {
  setAppState('noticesSortColumn', sortColumn);
  setAppState(
    'noticesSortDirection',
    currentSortColumn !== sortColumn
      ? 'ascending'
      : toggleSortDirection(currentSortDirection)
  );
}

function NoticesTable({ notices, count, loading, history }) {
  const {
    setAppState,
    noticesCurrentPage,
    noticesSortColumn,
    noticesSortDirection,
    noticesPageSize,
    noticesSearchTerms,
  } = useContext(AppContext);

  const searchActive = !isEmpty(pickBy(noticesSearchTerms));

  if (loading) {
    return (
      <div style={{ padding: '20px' }}>
        <Loader active />
      </div>
    );
  }

  return (
    <Table sortable celled selectable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell
            sorted={
              noticesSortColumn === NOTICE_DATE ? noticesSortDirection : null
            }
            onClick={() =>
              sortBy(
                NOTICE_DATE,
                noticesSortColumn,
                noticesSortDirection,
                setAppState
              )
            }
          >
            Notice Date
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={noticesSortColumn === DIED_ON ? noticesSortDirection : null}
            onClick={() =>
              sortBy(
                DIED_ON,
                noticesSortColumn,
                noticesSortDirection,
                setAppState
              )
            }
          >
            Died
          </Table.HeaderCell>
          {searchActive ? <Table.HeaderCell>Reference</Table.HeaderCell> : null}
          <Table.HeaderCell
            sorted={
              noticesSortColumn === FAMILY_NAME ? noticesSortDirection : null
            }
            onClick={() =>
              sortBy(
                FAMILY_NAME,
                noticesSortColumn,
                noticesSortDirection,
                setAppState
              )
            }
          >
            Family Name
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={
              noticesSortColumn === FIRST_NAMES ? noticesSortDirection : null
            }
            onClick={() =>
              sortBy(
                FIRST_NAMES,
                noticesSortColumn,
                noticesSortDirection,
                setAppState
              )
            }
          >
            First Names
          </Table.HeaderCell>
          {searchActive ? <Table.HeaderCell>Informant</Table.HeaderCell> : null}
          <Table.HeaderCell
            sorted={noticesSortColumn === VALUE ? noticesSortDirection : null}
            onClick={() =>
              sortBy(
                VALUE,
                noticesSortColumn,
                noticesSortDirection,
                setAppState
              )
            }
          >
            Value
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {notices.map((notice) => (
          <Table.Row
            key={notice._id}
            onClick={() => history.push(`/notice/${notice.reference}`)}
          >
            <Table.Cell>
              {moment(notice.noticedate).format('DD/MM/YYYY')}
            </Table.Cell>
            <Table.Cell>
              {moment(notice.diedon).format('DD/MM/YYYY')}
            </Table.Cell>
            {searchActive ? <Table.Cell>{notice.reference}</Table.Cell> : null}

            <Table.Cell>{notice.familyname}</Table.Cell>
            <Table.Cell>{notice.firstnames}</Table.Cell>
            {searchActive ? <Table.Cell>{notice.informant}</Table.Cell> : null}
            <Table.Cell>
              {accounting.formatMoney(notice.estatevalue, {
                symbol: '£',
                precision: 0,
                format: {
                  zero: '%s  --',
                },
              })}
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>

      <Table.Footer>
        <Table.Row>
          <Table.HeaderCell colSpan="8" className="center aligned">
            <Pagination
              activePage={noticesCurrentPage + 1}
              totalPages={
                noticesPageSize ? Math.ceil(count / noticesPageSize) : 1
              }
              onPageChange={(event, data) => {
                setAppState('noticesCurrentPage', data.activePage - 1);
              }}
              firstItem={{
                content: <Icon name="double left angle" />,
              }}
              prevItem={{ content: <Icon name="left angle" /> }}
              nextItem={{ content: <Icon name="right angle" /> }}
              lastItem={{
                content: <Icon name="double right angle" />,
              }}
            />
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    </Table>
  );
}

export default withRouter(NoticesTable);
