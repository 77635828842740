import gql from 'graphql-tag';

export const FILES = gql`
  query Files($reference: String!) {
    notice(reference: $reference) {
      _id
      files {
        id
        filename
        displayName
        uploadedBy
        uploadedAt
        url
        deleted
      }
    }
  }
`;

export const ADD_FILE = gql`
  mutation AddFileToNotice($reference: String!, $files: [FileInput]!) {
    addFilesToNotice(reference: $reference, files: $files)
  }
`;

export const DELETE_FILE = gql`
  mutation DeleteFile($noticeReference: String!, $fileId: ID!) {
    deleteFileFromNotice(noticeReference: $noticeReference, fileId: $fileId)
  }
`;

export const UPDATE_DISPLAY_NAME = gql`
  mutation UpdateDisplayName(
    $noticeReference: String!
    $fileId: ID!
    $displayName: String!
  ) {
    updateFileDisplayName(
      noticeReference: $noticeReference
      fileId: $fileId
      displayName: $displayName
    )
  }
`;
