import { Route } from 'react-router-dom';
import React from 'react';
import LogRocket from 'logrocket';
import * as Sentry from '@sentry/browser';
import { useAuth0 } from './react-auth0-spa';

export default function ({ component: Component, ...rest }) {
  const { loading, user, isAuthenticated, loginWithRedirect } = useAuth0();

  if (loading) return null;

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticated) {
          if (process.env.REACT_APP_LOGROCKET) {
            LogRocket.identify(user.preferred_username);

            LogRocket.getSessionURL((sessionUrl) => {
              Sentry.configureScope((scope) => {
                scope.setExtra('sessionUrl', sessionUrl);
              });
            });
          }

          if (process.env.REACT_APP_SENTRY_DSN) {
            Sentry.configureScope((scope) => {
              scope.setUser({
                username: user.preferred_username,
                email: user.email,
              });
            });
          }

          return <Component {...props} />;
        }

        loginWithRedirect();

        return null;
      }}
    />
  );
}
