import gql from 'graphql-tag';

export const UPDATE_NOTICE = gql`
mutation UpdateNotice($_id: ObjectID!, $input: NoticeInput!) {
  updateNotice(_id: $_id, input: $input) {
    _id
    noticedate
    source
    informant
    sex
    reference
    familyname
    familynameakas
    maidenname
    firstnames
    firstnameakas
    livedat
    diedat
    diedon
    estatevalue
  }
}
`;

export const NOTICE = gql`
  query Notice($reference: String!) {
    notice(reference: $reference) {
      _id
      noticedate
      source
      informant
      sex
      reference
      familyname
      familynameakas
      maidenname
      firstnames
      firstnameakas
      livedat
      diedat
      diedon
      estatevalue
    }
  }
`;

export const SOURCES = gql`
  query Sources {
    sources {
      _id
      name
    }
  }
`;
