import React from 'react';
import { withRouter } from 'react-router-dom';
import { Menu, Segment, Icon } from 'semantic-ui-react';
import NoticeGrantsListTable from './NoticeGrantsListTable.jsx';

const NoticeGrantsList = ({ history }) => (
  <Segment basic>
    <Menu attached="top">
      <Menu.Item as="a" name="New" onClick={() => history.push('grants/add')}>
        <Icon name="plus" />
        Add
      </Menu.Item>
    </Menu>

    <Segment attached="bottom">
      <NoticeGrantsListTable />
    </Segment>
  </Segment>
);

export default withRouter(NoticeGrantsList);
