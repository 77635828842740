import React from 'react';
import { Route, Switch } from 'react-router-dom';
import GrantsSearchPage from './search/GrantsSearchPage.jsx';
import GrantsList from './list/GrantsList.jsx';

const Grants = props => (
  <Switch>
    <Route path={'/grants/search'} render={() => <GrantsSearchPage />} />
    <Route path={'/grants'} render={() => <GrantsList {...props} />} />
  </Switch>
);

export default Grants;
