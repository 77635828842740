import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Menu, Form, Input, Button, Segment } from 'semantic-ui-react';
import { useAuth0 } from '../../react-auth0-spa';
import AppVersion from './AppVersion';

function MainMenu() {
  const [reference, setReference] = useState('');
  const { logout } = useAuth0();
  const history = useHistory();

  return (
    <div
      style={{
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        padding: '20px 20px 0 20px',
      }}
    >
      <div style={{ flex: 1 }}>
        <Menu vertical fluid>
          <Menu.Item name="notices" onClick={() => history.push('/notices')}>
            Notices
          </Menu.Item>
          <Menu.Item name="grants" onClick={() => history.push('/grants')}>
            Grants
          </Menu.Item>
          <Menu.Item
            name="workingsets"
            onClick={() => history.push('/workingsets')}
          >
            Working Sets
          </Menu.Item>
          <Menu.Item name="sources" onClick={() => history.push('/sources')}>
            Sources
          </Menu.Item>
          <Menu.Item
            name="solicitors"
            onClick={() => history.push('/solicitors')}
          >
            Solicitors
          </Menu.Item>
        </Menu>

        <Menu vertical fluid>
          <Menu.Item name="contacts" onClick={() => history.push('/contacts')}>
            Contacts
          </Menu.Item>
          <Menu.Item name="library" onClick={() => history.push('/library')}>
            Library
          </Menu.Item>
        </Menu>

        <Segment basic textAlign="center">
          <Form
            onSubmit={event => {
              history.push(`/notice/${reference}`);
              event.preventDefault();
            }}
          >
            <Input
              placeholder="reference #"
              onChange={(e, { value }) => {
                setReference(value);
              }}
            />
          </Form>
        </Segment>

        <Segment basic textAlign="center">
          <Button
            fluid
            onClick={() => {
              logout({ returnTo: process.env.REACT_APP_HOME_URL });
            }}
          >
            Log out
          </Button>
        </Segment>
      </div>

      <AppVersion />
    </div>
  );
}

export default MainMenu;
