import React, { useContext } from 'react';
import { Menu, Segment, Icon } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import isEmpty from 'lodash.isempty';
import MainPage from '../../main/MainPage.jsx';
import GrantsTable from './GrantsTable.jsx';
import { AppContext } from '../../../AppContext';

function GrantsList() {
  const {
    grantsQuery,
    setAppState,
  } = useContext(AppContext);

  const history = useHistory();

  return (
    <MainPage title="Grants">
      <Menu attached="top">
        <Menu.Item
          as="a"
          name="New"
          onClick={() => history.push('/grants/search')}
        >
          <Icon name="search" />
          Search
        </Menu.Item>

        {!isEmpty(grantsQuery) && (
          <Menu.Item
            as="a"
            name="Clear Search"
            onClick={() => {
              setAppState('grantsQuery', {});
              setAppState('grantsPageSize', 15);
              setAppState('grantsCurrentPage', 0);
            }}
          >
            <Icon name="ban" color="red" />
            Clear Search
          </Menu.Item>
        )}

        {!isEmpty(grantsQuery) && (
          <Menu.Item
            as="a"
            name="Show All"
            onClick={() => setAppState('grantsPageSize', 0)}
          >
            <Icon name="globe" />
            Show All
          </Menu.Item>
        )}
      </Menu>

      <Segment attached="bottom">
        <GrantsTable />
      </Segment>
    </MainPage>
  );
}

export default GrantsList;
