import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Mutation } from '@apollo/react-components';
import { useQuery } from '@apollo/react-hooks';
import { Loader } from 'semantic-ui-react';
import cogoToast from 'cogo-toast';
import NoticeMenu from './NoticeMenu.jsx';
import NoticeDetails from './NoticeDetails.jsx';
import NoticeGrants from './grants/NoticeGrants.jsx';
import NoticeNotes from './notes/NoticeNotes.jsx';
import NoticeWorkingSets from './workingsets/NoticeWorkingSets.jsx';
import MainPage from '../main/MainPage.jsx';
import NoticeFiles from './files/NoticeFiles';
import { NOTICE } from './gql';
import { NOTES, CREATE_NOTE } from './notes/gql';

function NoticePage({ match }) {
  const { reference, page } = match.params;
  const { loading, data } = useQuery(NOTICE, {
    variables: {
      reference,
    },
  });

  if (loading) {
    return (
      <div style={{ padding: '20px' }}>
        <Loader active />
      </div>
    );
  }

  const { notice } = data;

  if (!notice) {
    cogoToast.error(`Notice with reference number ${reference} was not found.`);
    return <Redirect to='/notices' />;
  }

  return (
    <MainPage title={notice.familyname}>
      <Route
        path={'/notice/:reference/:page?'}
        render={() => <NoticeMenu active={page || 'details'} />}
      />

      <Switch>
        <Route
          path={'/notice/:reference/grant(s?)'}
          render={() => <NoticeGrants />}
        />

        <Route
          path={'/notice/:reference/notes'}
          render={() => (
            <Mutation
              mutation={CREATE_NOTE}
              update={(cache, { data: { createNote } }) => {
                const cachedData = cache.readQuery({
                  query: NOTES,
                  variables: { reference },
                });

                cachedData.notice.notes.unshift(createNote);

                cache.writeQuery({
                  query: NOTES,
                  data: cachedData,
                });
              }}
            >
              {createNote => <NoticeNotes createNote={createNote} />}
            </Mutation>
          )}
        />

        <Route
          path={'/notice/:reference/workingsets'}
          render={() => <NoticeWorkingSets />}
        />

        <Route
          exact
          path={'/notice/:reference/files'}
          render={() => <NoticeFiles files={notice.files} />}
        />

        <Route
          path={'/notice/:reference'}
          render={() => {
            return <NoticeDetails notice={notice} />;
          }}
        />
      </Switch>
    </MainPage>
  );
}

export default NoticePage;
