import React from 'react';
import { Header, Segment, Grid } from 'semantic-ui-react';
import NoticesGrantsAddForm from './NoticeGrantsAddForm.jsx';

const NoticeGrantsAdd = () => (
  <div>
    <Header as="h5" attached="top">
      Add Grant
    </Header>
    <Segment attached>
      <Grid columns="equal">
        <Grid.Column />
        <Grid.Column width={15}>
          <NoticesGrantsAddForm />
        </Grid.Column>
      </Grid>
    </Segment>
  </div>
);

export default NoticeGrantsAdd;
