import React, { useEffect, useState } from 'react';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { Form, Button, Dropdown, Input, Message, Loader, Popup, Table } from 'semantic-ui-react';
import 'react-datepicker/dist/react-datepicker.css';
import { Formik, useFormikContext } from 'formik';
import MaskedInput from 'react-text-mask';
import * as yup from 'yup';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import accounting from 'accounting';
import withCreateNotice from './withCreateNotice';
import NoticeDateDatePicker from './NoticeDateDatePicker';
import DiedOnDatePicker from './DiedOnDatePicker';
import { SOURCES } from './withSources';
import { NOTICES } from '../list/gql';

function NoticesAddForm({ history, createNotice }) {
  const { loading, data: queryData } = useQuery(SOURCES);
  const [duplicateNotices, setDuplicateNotices] = useState([]);

  if (loading) {
    return (
      <div style={{ padding: '20px' }}>
        <Loader active />
      </div>
    );
  }

  const { sources: sourcesData } = queryData;

  return (
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={{
        noticeDate: new Date(),
        source: '',
        informant: '',
        familyName: '',
        familyNameAkas: '',
        maidenName: '',
        firstNames: '',
        firstNameAkas: '',
        livedAt: '',
        diedAt: '',
        diedOn: null,
        estateValue: 0,
        sexes: [
          { text: 'Male', value: 'Male' },
          { text: 'Female', value: 'Female' },
          { text: 'Unknown', value: 'Unknown' },
        ],
        sex: 'Unknown',
        sources: sourcesData.map((source) => ({
          text: source.name,
          value: source.name,
        })),
      }}
      validationSchema={yup.object().shape({
        noticeDate: yup
          .date()
          .transform((value, originalValue) => (originalValue ? value : undefined))
          .required()
          .label('Notice date'),
        source: yup.string().required().label('Source'),
        familyName: yup.string().required().label('Family name'),
        firstNames: yup.string().required().label('First names'),
        diedOn: yup
          .date()
          .transform((value, originalValue) => (originalValue ? value : undefined))
          .label('Died on date'),
        estateValue: yup.number().typeError('Estate value must be a numeric value').label('Estate value'),
      })}
      onSubmit={async (values) => {
        const { sexes, sources, ...rest } = values;

        // Convert all the object keys to lower case and save
        const notice = Object.keys(rest).reduce(
          (acc, key) => ({
            [key.toLowerCase()]: rest[key],
            ...acc,
          }),
          {}
        );

        const {
          data: {
            createNotice: { reference },
          },
        } = await createNotice(notice);

        history.push(`/notice/${reference}`);
      }}
    >
      {(formik) => (
        <Form onSubmit={formik.handleSubmit} error>
          <Form.Group inline>
            <Form.Field required>
              <label style={{ width: '150px' }}>Notice date</label>
              <div className="ui input">
                <NoticeDateDatePicker
                  value={formik.values.noticeDate}
                  onChange={formik.setFieldValue}
                  onBlur={formik.setFieldTouched}
                  error={formik.errors.noticeDate}
                  touched={formik.touched.noticeDate}
                />
              </div>
            </Form.Field>
          </Form.Group>

          <Form.Group inline>
            <Form.Field required>
              <label style={{ width: '150px' }}>Source</label>
              <Dropdown
                style={{ width: '250px' }}
                name="source"
                placeholder="Select source"
                selection
                search
                options={formik.values.sources}
                value={formik.values.source}
                onChange={(event, data) => {
                  formik.setFieldValue('source', data.value);
                }}
              />
              {formik.touched.source && formik.errors.source && (
                <Message error content={formik.errors.source} size="mini" />
              )}
            </Form.Field>
          </Form.Group>

          <Form.Group inline>
            <Form.Field>
              <label style={{ width: '150px' }}>Informant</label>
              <Input
                style={{ width: '250px' }}
                name="informant"
                value={formik.values.informant || ''}
                onChange={formik.handleChange}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group inline>
            <Form.Field>
              <label style={{ width: '150px' }}>Sex</label>
              <Dropdown
                style={{ width: '150px' }}
                name="sex"
                placeholder="Select sex"
                selection
                search
                options={formik.values.sexes}
                value={formik.values.sex}
                onChange={(event, data) => {
                  formik.setFieldValue('sex', data.value);
                }}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group inline>
            <Form.Field required>
              <label style={{ width: '150px' }}>Family name</label>
              <Input
                style={{ width: '250px' }}
                name="familyName"
                value={formik.values.familyName || ''}
                onChange={formik.handleChange}
              />
              {formik.touched.familyName && formik.errors.familyName && (
                <Message error content={formik.errors.familyName} size="mini" />
              )}
            </Form.Field>
          </Form.Group>

          <Form.Group inline>
            <Form.Field>
              <label style={{ width: '150px' }}>Family name AKAs</label>
              <Input
                style={{ width: '250px' }}
                name="familyNameAkas"
                value={formik.values.familyNameAkas || ''}
                onChange={formik.handleChange}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group inline>
            <Form.Field>
              <label style={{ width: '150px' }}>Maiden name</label>
              <Input
                style={{ width: '250px' }}
                name="maidenName"
                value={formik.values.maidenName || ''}
                onChange={formik.handleChange}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group inline>
            <Form.Field required>
              <label style={{ width: '150px' }}>First names</label>
              <Input
                style={{ width: '250px' }}
                name="firstNames"
                value={formik.values.firstNames || ''}
                onChange={formik.handleChange}
              />
              {formik.touched.firstNames && formik.errors.firstNames && (
                <Message error content={formik.errors.firstNames} size="mini" />
              )}
            </Form.Field>
          </Form.Group>

          <Form.Group inline>
            <Form.Field>
              <label style={{ width: '150px' }}>First name AKAs</label>
              <Input
                style={{ width: '250px' }}
                name="firstNameAkas"
                value={formik.values.firstNameAkas || ''}
                onChange={formik.handleChange}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group inline>
            <Form.Field>
              <label style={{ width: '150px' }}>Lived at</label>
              <Input
                style={{ width: '250px' }}
                name="livedAt"
                value={formik.values.livedAt || ''}
                onChange={formik.handleChange}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group inline>
            <Form.Field>
              <label style={{ width: '150px' }}>Died at</label>
              <Input
                style={{ width: '250px' }}
                name="diedAt"
                value={formik.values.diedAt || ''}
                onChange={formik.handleChange}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group inline>
            <Form.Field>
              <label style={{ width: '150px' }}>Died on</label>
              <div className="ui input">
                <DiedOnDatePicker
                  value={formik.values.diedOn}
                  onChange={formik.setFieldValue}
                  onBlur={formik.setFieldTouched}
                  error={formik.errors.diedOn}
                  touched={formik.touched.diedOn}
                />
              </div>
            </Form.Field>
          </Form.Group>

          {duplicateNotices.length > 0 ? (
            <Form.Field>
              <Popup
                content={
                  <Table>
                    <Table.Body>
                      {duplicateNotices.map((n, i) => {
                        console.log(n);
                        return (
                          <Table.Row key={`duplicate-${i}`}>
                            <Table.Cell>{n.familyname.toUpperCase()}</Table.Cell>
                            <Table.Cell>{n.firstnames}</Table.Cell>
                          </Table.Row>
                        );
                      })}
                    </Table.Body>
                  </Table>
                }
                trigger={
                  <a href="https://efs2.com" onClick={(e) => e.preventDefault()}>
                    Potential duplicate notices found!
                  </a>
                }
              />
            </Form.Field>
          ) : null}

          <Form.Group inline>
            <Form.Field>
              <label style={{ width: '150px' }}>Estate value</label>
              <Input
                style={{ width: '250px' }}
                name="estateValue"
                value={formik.values.estateValue || ''}
                onChange={(_event, data) => {
                  formik.setFieldValue('estateValue', accounting.unformat(data.value));
                }}
                input={<MaskedInput mask={createNumberMask({ prefix: '£' })} />}
              />
              {formik.touched.estateValue && formik.errors.estateValue && (
                <Message error content={formik.errors.estateValue} size="mini" />
              )}
            </Form.Field>
          </Form.Group>

          <Form.Group inline>
            <Button type="submit" disabled={formik.isSubmitting}>
              Add
            </Button>
          </Form.Group>

          <DuplicateDetector onDuplicatesFound={setDuplicateNotices} />
        </Form>
      )}
    </Formik>
  );
}

function DuplicateDetector({ onDuplicatesFound }) {
  const [getNotices, { data }] = useLazyQuery(NOTICES);
  const { values } = useFormikContext();

  useEffect(() => {
    if (data) {
      const duplicates = data.notices.notices || [];
      onDuplicatesFound(duplicates);
    }
  }, [data, onDuplicatesFound]);

  useEffect(() => {
    async function checkForDuplicateNotices() {
      await getNotices({
        variables: {
          query: {
            diedStartDate: values.diedOn,
            diedEndDate: values.diedOn,
          },
        },
      });
    }

    console.log(values);

    if (values.diedOn) {
      checkForDuplicateNotices();
    }
  }, [values, getNotices]);

  return null;
}

export default compose(withRouter, withCreateNotice)(NoticesAddForm);
