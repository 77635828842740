import React from 'react';
import { Icon, Popup, Button } from 'semantic-ui-react';
import useRole from '../../../utils/useRole';

export default function({ children, onEdit, onDelete }) {
  const { loading, role } = useRole();

  if (loading) return null;

  if (!['admin', 'senior', 'editor'].includes(role)) {
    return children;
  }

  return (
    <Popup
      basic
      hoverable
      trigger={children}
      content={
        <div>
          <Button icon onClick={onEdit}>
            <Icon name="pencil" />
          </Button>
          {['admin', 'senior'].includes(role) ? (
            <Button icon onClick={onDelete}>
              <Icon name="trash" />
            </Button>
          ) : null}
        </div>
      }
      on="hover"
      position="left center"
    />
  );
}
