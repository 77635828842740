import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import gql from 'graphql-tag';
import { Form, Button } from 'semantic-ui-react';
import { Formik } from 'formik';
import { Mutation } from '@apollo/react-components';
import * as Yup from 'yup';
import { AppContext } from '../../../AppContext';

const CREATE_WORKING_SET = gql`
  mutation createWorkingSet($name: String!, $query: NoticesQuery!) {
    createWorkingSetFromSearch(name: $name, query: $query) {
      _id
    }
  }
`;

function NoticesCreateWorkingSetForm({ history }) {
  const { noticesSearchTerms } = useContext(AppContext);

  const ValidationSchema = Yup.object().shape({
    name: Yup.string().required('A name must be specified.'),
  });

  return (
    <Mutation mutation={CREATE_WORKING_SET}>
      {createWorkingSet => (
        <Formik
          initialValues={{
            name: '',
          }}
          validationSchema={ValidationSchema}
          onSubmit={async values => {
            await createWorkingSet({
              variables: {
                name: values.name,
                query: noticesSearchTerms,
              },
            });

            history.push(`/workingset/${values.name}`);
          }}
        >
          {({ handleSubmit, handleChange, handleBlur, dirty, isValid }) => (
            <Form onSubmit={handleSubmit} noValidate>
              <Form.Group inline>
                <Form.Field required>
                  <label style={{ width: '100px' }} htmlFor={'name'}>
                    Name
                  </label>
                  <input
                    style={{ width: '300px' }}
                    name="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Form.Field>
              </Form.Group>

              <Button type="submit" disabled={!dirty || !isValid}>
                Create
              </Button>
            </Form>
          )}
        </Formik>
      )}
    </Mutation>
  );
}

export default withRouter(NoticesCreateWorkingSetForm);
