import React from 'react';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import {
  Form,
  Button,
  Loader,
  Segment,
  Message,
  Input,
  Dropdown,
} from 'semantic-ui-react';
import accounting from 'accounting';
import 'react-datepicker/dist/react-datepicker.css';
import { Formik } from 'formik';
import { useParams, useHistory } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import * as yup from 'yup';
import { CREATE_GRANT } from './gql';
import { GRANTS } from '../list/gql';

const SOLICITORS = gql`
  query solicitors($skip: Int, $limit: Int) {
    solicitors(skip: $skip, limit: $limit) {
      _count
      solicitors {
        _id
        name
      }
    }
  }
`;

function NoticesGrantsAddForm() {
  const { reference } = useParams();
  const history = useHistory();
  const { loading, data } = useQuery(SOLICITORS);
  const [createGrant] = useMutation(CREATE_GRANT);

  if (loading) {
    return (
      <Segment>
        <Loader />
      </Segment>
    );
  }

  const {
    solicitors: { solicitors },
  } = data;

  return (
    <Formik
      validateOnChange
      validateOnBlur
      initialValues={{
        extractionDate: new Date(),
        grantedTo: '',
        solicitor: '',
        value: '',
      }}
      validationSchema={yup.object().shape({
        extractionDate: yup
          .date()
          .transform((value, originalValue) =>
            originalValue ? value : undefined
          )
          .required()
          .label('Extraction date'),
        grantedTo: yup
          .string()
          .required()
          .label('Granted to'),
        solicitor: yup
          .string()
          .required()
          .label('Solicitor'),
        value: yup
          .number()
          .typeError('Estate value must be a numeric value')
          .positive()
          .label('Value'),
      })}
      onSubmit={async values => {
        const grant = {
          extractiondate: values.extractionDate,
          grantedto: values.grantedTo,
          solicitor: values.solicitor,
          value: `${accounting.unformat(values.value)}`,
          noticereference: reference,
        };

        await createGrant(
          { variables: { input: grant } },
          {
            refetchQueries: [
              {
                query: GRANTS,
                variables: {
                  query: { noticeReference: reference },
                },
              },
            ],
          }
        );

        history.push(`/notice/${reference}/grants`);
      }}
    >
      {formik => (
        <Form onSubmit={formik.handleSubmit} error>
          <Form.Group inline>
            <Form.Field required>
              <label style={{ width: '150px' }}>Extraction date</label>
              <div className="ui input">
                <DatePicker
                  style={{ width: '150px' }}
                  placeholderText="DD/MM/YYYY"
                  dateFormat="dd/MM/yyyy"
                  name="extractionDate"
                  selected={formik.values.extractionDate}
                  onChange={value => {
                    formik.setFieldValue('extractionDate', value);
                  }}
                />
              </div>
              {formik.touched.extractionDate &&
                formik.errors.extractionDate && (
                  <Message
                    error
                    content={formik.errors.extractionDate}
                    size="mini"
                  />
                )}
            </Form.Field>
          </Form.Group>

          <Form.Group inline>
            <Form.Field required>
              <label style={{ width: '150px' }}>Granted to</label>
              <Input
                style={{ width: '250px' }}
                name="grantedTo"
                value={formik.values.grantedTo}
                onChange={formik.handleChange}
              />
              {formik.touched.grantedTo && formik.errors.grantedTo && (
                <Message error content={formik.errors.grantedTo} size="mini" />
              )}
            </Form.Field>
          </Form.Group>

          <Form.Group inline>
            <Form.Field required>
              <label style={{ width: '150px' }}>Solicitor</label>
              <Dropdown
                style={{ width: '250px' }}
                name="solicitor"
                placeholder="Select solicitor"
                search
                selection
                options={solicitors.map(s => ({
                  key: s._id,
                  value: s.name,
                  text: s.name,
                }))}
                value={formik.values.solicitor}
                onChange={(event, data) => {
                  formik.setFieldValue('solicitor', data.value);
                }}
              />
              {formik.touched.solicitor && formik.errors.solicitor && (
                <Message error content={formik.errors.solicitor} size="mini" />
              )}
            </Form.Field>
          </Form.Group>

          <Form.Group inline>
            <Form.Field>
              <label style={{ width: '150px' }}>Value</label>
              <Input
                style={{ width: '250px' }}
                name="value"
                value={formik.values.value || ''}
                onChange={(event, data) => {
                  formik.setFieldValue(
                    'value',
                    accounting.unformat(data.value)
                  );
                }}
                input={<MaskedInput mask={createNumberMask({ prefix: '£' })} />}
              />
              {formik.touched.value && formik.errors.value && (
                <Message error content={formik.errors.value} size="mini" />
              )}
            </Form.Field>
          </Form.Group>

          <Form.Field>
            <Button type="submit">Add</Button>
          </Form.Field>
        </Form>
      )}
    </Formik>
  );
}

export default NoticesGrantsAddForm;
