import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useHistory, useParams } from 'react-router-dom';
import { Table, Button, Message, Confirm, Loader } from 'semantic-ui-react';
import moment from 'moment';
import accounting from 'accounting';
import { GRANTS, DELETE_GRANT } from './gql';

function NoticeGrantsListTable() {
  const history = useHistory();
  const { reference } = useParams();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [grantToDelete, setGrantToDelete] = useState(false);

  const { loading, data } = useQuery(GRANTS, {
    variables: { query: { noticeReference: reference } },
  });

  const [deleteGrant] = useMutation(DELETE_GRANT);

  async function handleDeleteConfirm() {
    await deleteGrant({ variables: { _id: grantToDelete } });
    setConfirmOpen(false);
    setGrantToDelete(undefined);
  }

  if (loading) return <Loader active />;

  const {
    grants: { grants: unfilteredGrants },
  } = data;

  const grants = unfilteredGrants.filter(grant => !grant.deleted);

  if (grants.length === 0) {
    return <Message size="large">No Grants for this Notice</Message>;
  }

  return (
    <div>
      <Table celled definition>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell>Date</Table.HeaderCell>
            <Table.HeaderCell>Grantee</Table.HeaderCell>
            <Table.HeaderCell>Solicitor</Table.HeaderCell>
            <Table.HeaderCell>Value</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {grants
            .map(grant => (
              <Table.Row key={grant._id}>
                <Table.Cell collapsing>
                  <Button.Group basic size="small">
                    <Button
                      icon="pencil"
                      onClick={() => history.push(`grant/${grant._id}/edit`)}
                    />
                    <Button
                      icon="trash"
                      onClick={() => {
                        setConfirmOpen(true);
                        setGrantToDelete(grant._id);
                      }}
                    />
                  </Button.Group>
                </Table.Cell>
                <Table.Cell>
                  {grant.extractiondate
                    ? moment(grant.extractiondate).format('DD/MM/YYYY')
                    : 'n/a'}
                </Table.Cell>
                <Table.Cell>{grant.grantedto}</Table.Cell>
                <Table.Cell>{grant.solicitor}</Table.Cell>
                <Table.Cell>
                  {accounting.formatMoney(grant.value, {
                    symbol: '£',
                    precision: 0,
                    format: {
                      zero: '%s  --',
                    },
                  })}
                </Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
      </Table>

      <Confirm
        confirmButton="Delete"
        open={confirmOpen}
        onCancel={() => setConfirmOpen(true)}
        onConfirm={handleDeleteConfirm}
      />
    </div>
  );
}

export default NoticeGrantsListTable;
