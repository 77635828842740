import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { Dropdown, Loader } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';

const QUERY = gql`
  query workingSetsNotContainingNotice($reference: String!) {
    workingSetsNotContainingNotice(reference: $reference) {
      _count
      workingSets {
        _id
        name
      }
    }
  }
`;

function NoticeWorkingSetsAddSelect({
  match: {
    params: { reference },
  },
  onChange,
}) {
  const { loading, data } = useQuery(QUERY, {
    variables: { reference },
  });

  if (loading) return <Loader active />;

  const {
    workingSetsNotContainingNotice: { workingSets },
  } = data;

  return (
    <Dropdown
      placeholder="Working Sets"
      search
      selection
      options={workingSets.map(({ name }) => ({
        text: name,
        value: name,
      }))}
      onChange={onChange}
    />
  );
}

export default withRouter(NoticeWorkingSetsAddSelect);
