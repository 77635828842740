import React from 'react';
import { Card, Table } from 'semantic-ui-react';
import get from 'lodash.get';

export default ({ resource }) => {
  const places = get(resource, 'geo.places') || [];
  const counties = get(resource, 'geo.counties') || [];
  const countries = get(resource, 'geo.countries') || [];

  return (
    <Card centered fluid>
      <Card.Content>
        <Card.Header>{get(resource, 'title')}</Card.Header>
        <Card.Description>{get(resource, 'notes')}</Card.Description>
      </Card.Content>

      <Card.Content>
        <Table compact celled>
          <Table.Body>
            <Table.Row>
              <Table.Cell colSpan={2}>
                <b>Author(s):</b>&nbsp;&nbsp;
                {(resource.authors || [])
                  .map(entry => `${entry.fname} ${entry.sname}`, [])
                  .join(', ')}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell colSpan={2}>
                <b>Places:</b>&nbsp;&nbsp;
                {places.join(', ')}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell width={8}>
                <b>Counties:</b>&nbsp;&nbsp;
                {counties.join(', ')}
              </Table.Cell>
              <Table.Cell>
                <b>Countries:</b>&nbsp;&nbsp;
                {countries.join(', ')}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell width={8}>
                <b>Publisher:</b>&nbsp;&nbsp;{get(resource, 'publisher')}
              </Table.Cell>
              <Table.Cell>
                <b>Published:</b>&nbsp;&nbsp;{get(resource, 'published')}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell width={8}>
                <b>ISBN:</b>&nbsp;&nbsp;{get(resource, 'isbn')}
              </Table.Cell>
              <Table.Cell>
                <b>ISSN:</b>&nbsp;&nbsp;{get(resource, 'issn')}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell width={8}>
                <b>Location:</b>&nbsp;&nbsp;{get(resource, 'location')}
              </Table.Cell>
              <Table.Cell>
                <b>Coverage:</b>&nbsp;&nbsp;{get(resource, 'coverage.from')}
                {get(resource, 'coverage.to')
                  ? `-${get(resource, 'coverage.to')}`
                  : ''}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell width={8}>
                <b>Topic:</b>&nbsp;&nbsp;{get(resource, 'topic')}
              </Table.Cell>
              <Table.Cell>
                <b>ID:</b>&nbsp;&nbsp;{get(resource, 'id')}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </Card.Content>
    </Card>
  );
};
