import React from 'react';
import { Grid } from 'semantic-ui-react';
import MainPage from '../../main/MainPage.jsx';
import GrantsSearchForm from './GrantsSearchForm.jsx';

const GrantsSearchPage = () => (
  <MainPage title="Search Grants">
    <Grid columns="equal">
      <Grid.Column />
      <Grid.Column width={10}>
        <GrantsSearchForm />
      </Grid.Column>
      <Grid.Column />
    </Grid>
  </MainPage>
);

export default GrantsSearchPage;
