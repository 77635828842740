import React, { useState, useContext, useRef, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { Form, Button, Input, Dropdown, Loader } from 'semantic-ui-react';
import MaskedInput from 'react-text-mask';
import DatePicker from 'react-datepicker';
import pickBy from 'lodash.pickby';
import 'react-datepicker/dist/react-datepicker.css';
import { AppContext } from '../../../AppContext.js';

const SOURCES = gql`
  query Sources {
    sources {
      _id
      name
    }
  }
`;

function NoticesSearchForm({ history }) {
  const { loading, data } = useQuery(SOURCES);
  const { noticesSearchTerms, setAppState } = useContext(AppContext);
  const [noticeStartDate, setNoticeStartDate] = useState(null);
  const [noticeEndDate, setNoticeEndDate] = useState(null);
  const [diedStartDate, setDiedStartDate] = useState(null);
  const [diedEndDate, setDiedEndDate] = useState(null);
  const [diedIn, setDiedIn] = useState('');
  const [reference, setReference] = useState('');
  const [familyName, setFamilyName] = useState('');
  const [firstNames, setFirstNames] = useState('');
  const [source, setSource] = useState('');
  const [informant, setInformant] = useState('');
  const [notes, setNotes] = useState('');
  const familyNameRef = useRef(null);

  useEffect(() => {
    // familyNameRef.current.focus();

    // Restore search state
    setNoticeStartDate(noticesSearchTerms.noticeStartDate);
    setNoticeEndDate(noticesSearchTerms.noticeEndDate);
    setDiedStartDate(noticesSearchTerms.diedStartDate);
    setDiedEndDate(noticesSearchTerms.diedEndDate);
    setDiedIn(noticesSearchTerms.diedIn);
    setReference(noticesSearchTerms.reference);
    setFamilyName(noticesSearchTerms.familyName);
    setFirstNames(noticesSearchTerms.firstNames);
    setSource(noticesSearchTerms.source);
    setInformant(noticesSearchTerms.informant);
    setNotes(noticesSearchTerms.notes);
  }, [noticesSearchTerms]);

  if (loading) return <Loader />;

  const { sources } = data;

  const sourceOptions = [
    {
      key: 'none',
      value: 'none',
      text: '(none)',
    },
  ].concat(
    sources.map(s => ({
      key: s._id,
      value: s.name,
      text: s.name,
    }))
  );

  function handleSubmit(e) {
    const query = pickBy({
      reference,
      noticeStartDate,
      noticeEndDate,
      diedStartDate,
      diedEndDate,
      diedIn,
      firstNames,
      familyName,
      source,
      informant,
      notes,
    });

    setAppState('noticesSearchTerms', query);
    setAppState('noticesCurrentPage', 0);
    history.push('/notices');

    e.preventDefault();
  }

  return (
    <Form onSubmit={handleSubmit} noValidate autoComplete="off">
      <Form.Group inline>
        <label style={{ width: '100px' }}>Dated between</label>
        <div style={{ width: '150px' }}>
          <DatePicker
            dateFormat="dd/MM/yyyy"
            name="noticeStartDate"
            onChange={date => {
              setNoticeStartDate(date);
            }}
            selected={noticeStartDate}
            onBlur={() => setNoticeEndDate(noticeStartDate)}
          />
        </div>

        <label style={{ marginLeft: '10px', marginRight: '10px' }}>and</label>
        <div style={{ width: '150px' }}>
          <DatePicker
            dateFormat="dd/MM/yyyy"
            name="noticeEndDate"
            onChange={date => setNoticeEndDate(date)}
            selected={noticeEndDate}
          />
        </div>
      </Form.Group>

      <Form.Group inline>
        <label style={{ width: '100px' }}>Died between</label>
        <div style={{ width: '150px' }}>
          <DatePicker
            dateFormat="dd/MM/yyyy"
            name="diedDateStart"
            onChange={date => {
              setDiedStartDate(date);
            }}
            selected={diedStartDate}
            onBlur={() => setDiedEndDate(diedStartDate)}
          />
        </div>

        <label style={{ marginLeft: '10px', marginRight: '10px' }}>and</label>
        <div style={{ width: '150px' }}>
          <DatePicker
            dateFormat="dd/MM/yyyy"
            name="diedDateEnd"
            onChange={date => setDiedEndDate(date)}
            selected={diedEndDate}
          />
        </div>
      </Form.Group>
      <Form.Group inline>
        <label style={{ width: '100px' }}>Died in</label>
        <Input
          name="diedIn"
          style={{ width: '300px' }}
          value={diedIn}
          onChange={e => setDiedIn(e.target.value)}
        />
      </Form.Group>
      <Form.Group inline>
        <label style={{ width: '100px' }}>Reference</label>
        <Input
          name="reference"
          style={{ width: '300px' }}
          value={reference}
          onChange={e => setReference(e.target.value)}
          input={
            <MaskedInput mask={[/\d/, /\d/, /\d/, /\d/, /\d/]} guide={false} />
          }
        />
      </Form.Group>
      <Form.Group inline>
        <label style={{ width: '100px' }}>Family name</label>
        <Input
          name="familyName"
          style={{ width: '300px' }}
          value={familyName}
          onChange={e => setFamilyName(e.target.value)}
          ref={familyNameRef}
        />
      </Form.Group>
      <Form.Group inline>
        <label style={{ width: '100px' }}>First names</label>
        <Input
          name="firstNames"
          style={{ width: '300px' }}
          value={firstNames}
          onChange={e => setFirstNames(e.target.value)}
        />
      </Form.Group>
      <Form.Group inline>
        <label style={{ width: '100px' }}>Source</label>
        <Dropdown
          name="source"
          placeholder="Select Source"
          search
          selection
          value={source}
          options={sourceOptions}
          onChange={(e, selected) => setSource(selected.value)}
        />
      </Form.Group>
      <Form.Group inline>
        <label style={{ width: '100px' }}>Informant</label>
        <Input
          name="informant"
          style={{ width: '300px' }}
          value={informant}
          onChange={e => setInformant(e.target.value)}
        />
      </Form.Group>
      <Form.Group inline>
        <label style={{ width: '100px' }}>Notes</label>
        <Input
          name="notes"
          style={{ width: '300px' }}
          value={notes}
          onChange={e => setNotes(e.target.value)}
        />
      </Form.Group>
      <Form.Group inline>
        <Button type="submit" floated="right">
          Search
        </Button>
      </Form.Group>
    </Form>
  );
}

export default withRouter(NoticesSearchForm);
