import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useHistory, useParams } from 'react-router-dom';
import { Table, Segment, Menu, Icon, Confirm, Loader } from 'semantic-ui-react';
import moment from 'moment';
import accounting from 'accounting';
import useRole from '../../utils/useRole';
import { DELETE_NOTICE } from './gql.js';

function NoticeDeleteButton({ onClick }) {
  const { loading, role } = useRole();

  if (loading) return null;

  if (['admin', 'senior'].includes(role)) {
    return (
      <Menu.Item as="a" name="Delete" onClick={onClick}>
        <Icon name="trash" />
        Delete
      </Menu.Item>
    );
  }
  return null;
}

function NoticeDetails(props) {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [noticeToDelete, setNoticeToDelete] = useState();
  const history = useHistory();
  const { reference } = useParams();

  const [deleteNotice] = useMutation(DELETE_NOTICE);

  if (props.loading) {
    return (
      <div style={{ padding: '20px' }}>
        <Loader active />
      </div>
    );
  }

  const { notice } = props;

  async function handleDelete() {
    await deleteNotice({
      variables: {
        _id: noticeToDelete,
      },
    });

    history.push('/notices');
  }

  function handleDeleteCancel() {
    setConfirmOpen(false);
    setNoticeToDelete(undefined);
  }

  function handleDeleteConfirm() {
    setConfirmOpen(true);
    setNoticeToDelete(props.notice._id);
  }

  return (
    <div>
      <Segment basic>
        <Menu attached="top">
          <Menu.Item
            as="a"
            name="Edit"
            onClick={() => history.push(`/notice/${reference}/edit`)}
          >
            <Icon name="pencil" />
            Edit
          </Menu.Item>

          <NoticeDeleteButton onClick={handleDeleteConfirm} />
        </Menu>

        <Segment attached="bottom">
          <Table basic="very">
            <Table.Body>
              <Table.Row>
                <Table.Cell width={4}>
                  <b>Notice date</b>
                </Table.Cell>
                <Table.Cell>
                  {moment(notice.noticedate).format('DD/MM/YYYY')}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell width={4}>
                  <b>Source</b>
                </Table.Cell>
                <Table.Cell>{notice.source}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell width={4}>
                  <b>Informant</b>
                </Table.Cell>
                <Table.Cell>{notice.informant}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell width={4}>
                  <b>Sex</b>
                </Table.Cell>
                <Table.Cell>{notice.sex}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell width={4}>
                  <b>Reference</b>
                </Table.Cell>
                <Table.Cell>{notice.reference}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell width={4}>
                  <b>Family name</b>
                </Table.Cell>
                <Table.Cell>{notice.familyname}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell width={4}>
                  <b>Family name AKAs</b>
                </Table.Cell>
                <Table.Cell>{notice.familynameakas}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell width={4}>
                  <b>Maiden name</b>
                </Table.Cell>
                <Table.Cell>{notice.maidenname}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell width={4}>
                  <b>First names</b>
                </Table.Cell>
                <Table.Cell>{notice.firstnames}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell width={4}>
                  <b>First name AKAs</b>
                </Table.Cell>
                <Table.Cell>{notice.firstnameakas}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell width={4}>
                  <b>Lived at</b>
                </Table.Cell>
                <Table.Cell>{notice.livedat}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell width={4}>
                  <b>Died at</b>
                </Table.Cell>
                <Table.Cell>{notice.diedat}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell width={4}>
                  <b>Died on</b>
                </Table.Cell>
                <Table.Cell>
                  {notice.diedon && moment(notice.diedon).format('DD/MM/YYYY')}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell width={4}>
                  <b>Estate value</b>
                </Table.Cell>
                <Table.Cell>
                  {accounting.formatMoney(notice.estatevalue, {
                    symbol: '£',
                    precision: 0,
                    format: {
                      zero: '%s  --',
                    },
                  })}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Segment>
      </Segment>
      <Confirm
        confirmButton="Delete"
        open={confirmOpen}
        onCancel={handleDeleteCancel}
        onConfirm={handleDelete}
      />
    </div>
  );
}

export default NoticeDetails;
