import React from 'react';

export const AppContext = React.createContext();

export class AppContextProvider extends React.Component {
  state = {
    setAppState: (key, val) => {
      if (this.state[key] === val) return;

      if (window.DEBUG) {
        console.debug(`Before (${key}=${val}):`);
        console.table(this.state);
      }

      this.setState(
        {
          [key]: val,
        },
        () => {
          if (window.DEBUG) {
            console.debug(`After (${key}=${val}):`);
            console.table(this.state);
          }
        },
      );
    },

    // Grants
    grantsQuery: {},
    grantsCurrentPage: 0,
    grantsPageSize: 15,
    grantsSortColumn: 'extractiondate',
    grantsSortDirection: 'descending',

    // Solicitors
    solicitorsCurrentPage: 0,
    solicitorsPageSize: 15,

    // Users
    usersCurrentPage: 0,
    usersPageSize: 15,

    // Sources
    sourcesCurrentPage: 0,
    sourcesPageSize: 15,

    // Working Set
    workingSetPageSize: 15,
    workingSetCurrentPage: 0,
    workingSetSortColumn: 'source',
    workingSetSortDirection: 'descending',

    // Notices
    noticesPageSize: 15,
    noticesCurrentPage: 0,
    noticesSortColumn: 'noticedate',
    noticesSortDirection: 'descending',
    noticesQuickSearchTerm: '',
    noticesSearchTerms: {
      diedIn: '',
      reference: '',
      familyName: '',
      firstNames: '',
      source: '',
      informant: '',
      notes: '',
    },

    // Working Sets
    workingSetsPageSize: 15,
    workingSetsCurrentPage: 0,
    workingSetsSortColumn: 'createdOn',
    workingSetsSortDirection: 'descending',
  };

  render() {
    return (
      <AppContext.Provider value={this.state}>
        {this.props.children}
      </AppContext.Provider>
    );
  }
}
