import React, { useContext } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { withRouter } from 'react-router-dom';
import gql from 'graphql-tag';
import { Form, Button } from 'semantic-ui-react';
import { Field, reduxForm } from 'redux-form';
import Validator from 'validatorjs';
import { renderInput } from '../../common/forms';
import { SOURCES } from '../SourcesTable';
import { AppContext } from '../../../AppContext';

export const ADD_SOURCE = gql`
  mutation createSource($input: SourceInput!) {
    createSource(input: $input) {
      _id
      name
      lowerCaseName
    }
  }
`;

function SourcesAddForm(props) {
  const { handleSubmit, pristine, valid, history } = props;
  const [createSource] = useMutation(ADD_SOURCE);
  const { sourcesPageSize, sourcesCurrentPage } = useContext(AppContext);

  async function submit(values) {
    await createSource({
      variables: { input: values },
      refetchQueries: [
        {
          query: SOURCES,
          variables: {
            skip: sourcesPageSize * sourcesCurrentPage,
            limit: sourcesPageSize,
          },
        },
      ],
      // optimisticResponse: {
      //   __typename: 'Mutation',
      //   createSource: {
      //     __typename: 'Source',
      //     _id: `${Date.now()}`,
      //     name: 'aa-88',
      //     lowerCaseName: 'aa-88',
      //   },
      // },
    });
    history.push('/sources/');
  }

  return (
    <Form onSubmit={handleSubmit(submit)} noValidate>
      <Form.Group inline>
        <Field required name="name" label="Name" component={renderInput} />
      </Form.Group>

      <Button type="submit" disabled={!valid || pristine}>
        Submit
      </Button>
    </Form>
  );
}

const validate = data => {
  const rules = {
    name: 'required|min:3',
  };

  const messages = {
    'required.name': 'Source name is required.',
    'min.name': 'Source name must be at least 3 characters.',
  };

  const validator = new Validator(data, rules, messages);

  return validator.passes() ? {} : validator.errors.all();
};

export default withRouter(
  reduxForm({
    form: 'SourcesAddForm',
    validate,
    initialValues: {
      name: '',
    },
  })(SourcesAddForm)
);
