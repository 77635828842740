import React, { useContext } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { Table, Button, Loader, Pagination, Icon } from 'semantic-ui-react';
import { AppContext } from '../../AppContext';

const USERS = gql`
  query Users($skip: Int, $limit: Int) {
    users(skip: $skip, limit: $limit) {
      _count
      users {
        _id
        username
        profile {
          name
        }
        roles
      }
    }
  }
`;

function UsersTable(props) {
  const { setAppState, usersPageSize, usersCurrentPage } = useContext(
    AppContext
  );

  const { loading, data } = useQuery(USERS, {
    variables: {
      skip: usersPageSize * usersCurrentPage,
      limit: usersPageSize,
    },
  });

  if (loading) return <Loader active />;

  const {
    users: { users, _count },
  } = data;

  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>Username</Table.HeaderCell>
          <Table.HeaderCell>Roles</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {users.map(user => (
          <Table.Row key={user._id}>
            <Table.Cell>{user.profile.name}</Table.Cell>
            <Table.Cell>{user.username}</Table.Cell>
            <Table.Cell>{user.roles && user.roles.join(', ')}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>

      <Table.Footer>
        <Table.Row>
          <Table.HeaderCell colSpan="3" className="center aligned">
            <Pagination
              activePage={usersCurrentPage + 1}
              totalPages={Math.ceil(_count / usersPageSize)}
              onPageChange={(event, pageData) => {
                setAppState('usersCurrentPage', pageData.activePage - 1);
              }}
              firstItem={{ content: <Icon name="double left angle" /> }}
              prevItem={{ content: <Icon name="left angle" /> }}
              nextItem={{ content: <Icon name="right angle" /> }}
              lastItem={{ content: <Icon name="double right angle" /> }}
            />

            <Button
              floated="right"
              onClick={() => props.history.push('/users/add')}
            >
              Add User
            </Button>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    </Table>
  );
}

export default UsersTable;
