import React from 'react';
import { connect } from 'react-redux';
import { Form, Button } from 'semantic-ui-react';
import {
  Field,
  reduxForm,
  change,
  formValueSelector,
} from 'redux-form';
import Validator from 'validatorjs';
import userRoles from './userRoles.js';
import { renderInput } from '../../common/forms';

const RoleField = ({ input }) =>
  <Form.Dropdown
    selection
    {...input}
    value={input.value}
    onChange={(param, data) => {
      input.onChange(data.value);
    }}
    options={userRoles}
    label="User Role"
    placeholder="User Role"
    required
  />;

let UsersAddForm = props => {
  const { handleSubmit, userName, updateUserName, pristine, valid } = props;

  return (
    <Form onSubmit={handleSubmit} noValidate>
      <Form.Group inline>
        <Field
          required
          name="firstName"
          label="First name"
          component={renderInput}
          onBlur={() => updateUserName(userName)}
        />
      </Form.Group>

      <Form.Group inline>
        <Field
          required
          name="lastName"
          label="Last name"
          component={renderInput}
          onBlur={() => updateUserName(userName)}
        />
      </Form.Group>

      <Form.Group inline>
        <Field
          readOnly
          tabIndex="-1"
          name="userName"
          label="Username"
          component={renderInput}
        />
      </Form.Group>

      <Form.Group inline>
        <Field
          required
          name="password"
          label="Password"
          type="password"
          component={renderInput}
          onBlur={() => updateUserName(userName)}
        />
      </Form.Group>

      <Form.Group inline>
        <Field name="role" component={RoleField} />
      </Form.Group>

      <Button type="submit" disabled={!valid || pristine}>
        Submit
      </Button>
    </Form>
  );
};

const selector = formValueSelector('usersAddForm');

const mapStateToProps = state => {
  const firstName = selector(state, 'firstName');
  const lastName = selector(state, 'lastName');

  return {
    userName:
      firstName.length && lastName.length
        ? `${firstName.toLowerCase()}.${lastName.toLowerCase()}`
        : '',
  };
};

const mapDispatchToProps = dispatch => ({
  updateUserName: userName => {
    dispatch(change('usersAddForm', 'userName', userName));
  },
});

UsersAddForm = connect(mapStateToProps, mapDispatchToProps)(UsersAddForm);

const validate = data => {
  const rules = {
    firstName: 'required|min:3',
    lastName: 'required|min:3',
    password: 'required',
  };

  const messages = {
    'required.firstName': 'First name is required.',
    'min.firstName': 'First name must be at least 3 characters.',
    'required.lastName': 'Last name is required.',
    'min.lastName': 'Last name must be at least 3 characters.',
  };

  const validator = new Validator(data, rules, messages);

  return validator.passes() ? {} : validator.errors.all();
};

export default reduxForm({
  form: 'usersAddForm',
  validate,
  initialValues: {
    firstName: '',
    lastName: '',
    userName: '',
    password: '',
    role: 'default',
  },
})(UsersAddForm);
