import React from 'react';
import { Icon, Popup, Button } from 'semantic-ui-react';
import useRole from '../../utils/useRole';

function DeletePopup({ children, onDelete }) {
  const { loading, role } = useRole();

  if (loading) return null;

  if (!['admin', 'senior'].includes(role)) {
    return children;
  }

  return (
    <Popup
      basic
      hoverable
      trigger={children}
      content={
        <Button icon onClick={onDelete}>
          <Icon name="trash" />
        </Button>
      }
      on="hover"
      position="left center"
    />
  );
}

export default DeletePopup;
