import gql from 'graphql-tag';

export const NOTICES = gql`
  query Notices(
    $query: NoticesQuery
    $skip: Int
    $limit: Int
    $sort: SortSpec
  ) {
    notices(query: $query, skip: $skip, limit: $limit, sort: $sort) {
      _count
      notices {
        _id
        reference
        noticedate
        diedon
        familyname
        firstnames
        estatevalue
        informant
      }
    }
  }
`;

export const NOTICES_QUICK_SEARCH = gql`
  query noticesQuickSearch(
    $searchTerm: String!
    $skip: Int
    $limit: Int
    $sort: SortSpec
  ) {
    noticesQuickSearch(
      searchTerm: $searchTerm
      skip: $skip
      limit: $limit
      sort: $sort
    ) {
      _count
      notices {
        _id
        noticedate
        diedon
        familyname
        firstnames
        estatevalue
        reference
        diedat
        source
        informant
      }
    }
  }
`;
