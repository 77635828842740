import React from 'react';
import { Form, Label } from 'semantic-ui-react';

const renderInput = props => {
  const {
    input,
    meta,
    placeholder,
    label,
    type,
    required,
    readOnly,
    tabIndex,
  } = props;

  return (
    <Form.Field required={required} error={meta.touched && !!meta.error}>
      <label style={{ width: '100px' }} htmlFor={input.name}>
        {label}
      </label>
      <input
        style={{ width: '300px' }}
        {...input}
        placeholder={placeholder}
        type={type}
        readOnly={readOnly}
        tabIndex={tabIndex}
      />
      {meta.touched &&
        !!meta.error &&
        <Label basic color="red" pointing="left">
          {meta.error[0]}
        </Label>}
    </Form.Field>
  );
};

export default renderInput;
