import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Segment } from 'semantic-ui-react';

import NoticeGrantsList from './list/NoticeGrantsList.jsx';
import NoticeGrantsAdd from './add/NoticeGrantsAdd.jsx';
import NoticeGrantEdit from './edit/NoticeGrantEdit.jsx';

const NoticeGrants = () => (
  <Segment basic>
    <Switch>
      <Route
        path={'/notice/:reference/edit'}
        render={() => <h4>Edit!!</h4>}
      />
      <Route
        path={'/notice/:reference/grants/add'}
        render={() => <NoticeGrantsAdd />}
      />
      <Route
        path={'/notice/:reference/grant/:grant/edit'}
        render={() => <NoticeGrantEdit />}
      />
      <Route
        path={'/notice/:reference/grants'}
        render={() => <NoticeGrantsList />}
      />
    </Switch>
  </Segment>
);

export default NoticeGrants;
