import React from 'react';
import { Card, Icon } from 'semantic-ui-react';
import get from 'lodash.get';

export default ({ contact }) =>
  contact ? (
    <Card centered fluid>
      <Card.Content>
        <Card.Header>{`${get(contact, 'firstname')} ${get(
          contact,
          'lastname',
        )}`}</Card.Header>

        <Card.Meta>{get(contact, 'office')}</Card.Meta>
      </Card.Content>

      {get(contact, 'email') ? (
        <Card.Content extra>
          <Icon name="mail" />
          {get(contact, 'email')}
        </Card.Content>
      ) : null}

      {get(contact, 'phoneno') ? (
        <Card.Content extra>
          <Icon name="phone" />
          {get(contact, 'phoneno') ? `${get(contact, 'phoneno')} ` : ''}
          {get(contact, 'extno') ? `(ext. ${get(contact, 'extno')})` : ''}
        </Card.Content>
      ) : null}

      {get(contact, 'mobno') ? (
        <Card.Content extra>
          <Icon name="mobile" />
          {get(contact, 'mobno') ? `${get(contact, 'mobno')} ` : ''}
          {get(contact, 'mobext') ? `(ext. ${get(contact, 'mobext')})` : ''}
        </Card.Content>
      ) : null}
    </Card>
  ) : null;
