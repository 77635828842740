import React from 'react';
import gql from 'graphql-tag';
import { Mutation } from '@apollo/react-components';
import { WORKING_SETS } from '../list/gql';

const ADD_NOTICE_TO_WORKING_SET = gql`
  mutation addNoticeToWorkingSet(
    $reference: String!
    $workingSetName: String!
  ) {
    addNoticeToWorkingSet(
      reference: $reference
      workingSetName: $workingSetName
    ) {
      _id
      workingsets {
        name
      }
    }
  }
`;

const withAddNoticeToWorkingSet = Component => props => (
  <Mutation mutation={ADD_NOTICE_TO_WORKING_SET}>
    {mutate => (
      <Component
        {...props}
        addNoticeToWorkingSet={(reference, workingSetName) => {
          mutate({
            variables: { reference, workingSetName },
            refetchQueries: [
              {
                query: WORKING_SETS,
                variables: { reference },
              },
            ],
          });
        }}
      />
    )}
  </Mutation>
);

export default withAddNoticeToWorkingSet;
