import { useState, useEffect } from 'react';
import jwt from 'jsonwebtoken';
import { useAuth0 } from '../react-auth0-spa';

export default function useRole() {
  const { loading: auth0Loading, getTokenSilently } = useAuth0();
  const [ loading, setLoading ] = useState(true);
  const [ role, setRole ] = useState();

  useEffect(() => {
    async function fn() {
      if (!auth0Loading) {
        const token = await getTokenSilently();

        const {
          permissions: [decodedRole],
        } = jwt.decode(token);

        setRole(decodedRole);
        setLoading(false);
      }
    }

    fn();
  }, [auth0Loading, getTokenSilently]);

  return { loading, role };
}
