import React from 'react';
import { Feed } from 'semantic-ui-react';
import MainPage from '../main/MainPage.jsx';

function ActivityPage() {
  return (
    <MainPage title="Activity">
      <Feed>
        <Feed.Event>
          <Feed.Label>
            <img alt="Actor Profile" src="/avatar.png" />
          </Feed.Label>
          <Feed.Content>
            <Feed.Summary>
              <Feed.User>Elliot Fu</Feed.User> added you as a friend
              <Feed.Date>1 Hour Ago</Feed.Date>
            </Feed.Summary>
          </Feed.Content>
        </Feed.Event>

        <Feed.Event>
          <Feed.Label image="/avatar.png" />
          <Feed.Content>
            <Feed.Summary>
              <a href="file://">Helen Troy</a> added <a href="file://">2 new illustrations</a>
              <Feed.Date>4 days ago</Feed.Date>
            </Feed.Summary>
          </Feed.Content>
        </Feed.Event>

        <Feed.Event>
          <Feed.Label image="/avatar.png" />
          <Feed.Content>
            <Feed.Summary
              date="2 Days Ago"
              user="Jenny Hess"
              content="added you as a friend"
            />
          </Feed.Content>
        </Feed.Event>

        <Feed.Event>
          <Feed.Label image="/avatar.png" />
          <Feed.Content>
            <Feed.Summary>
              <a href="file://">Joe Henderson</a> posted on his page
              <Feed.Date>3 days ago</Feed.Date>
            </Feed.Summary>
            <Feed.Extra text>
              Ours is a life of constant reruns. We're always circling back to
              where we'd we started, then starting all over again. Even if we
              don't run extra laps that day, we surely will come back for more
              of the same another day soon.
            </Feed.Extra>
          </Feed.Content>
        </Feed.Event>

        <Feed.Event>
          <Feed.Label image="/avatar.png" />
          <Feed.Content>
            <Feed.Summary>
              <a href="file://">Justen Kitsune</a> added <a href="file://">2 new photos</a> of you
              <Feed.Date>4 days ago</Feed.Date>
            </Feed.Summary>
          </Feed.Content>
        </Feed.Event>
      </Feed>
    </MainPage>
  );
}

export default ActivityPage;
