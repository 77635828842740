import React, { useContext, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Menu, Segment, Icon, Input } from 'semantic-ui-react';
import MainPage from '../main/MainPage.jsx';
import WorkingSetsTable from './WorkingSetsTable.jsx';
import { AppContext } from '../../AppContext.js';

const KEYCODE_ENTER = 13;

function WorkingSetsPage({ history }) {
  const { workingSetsQuickSearchTerm, setAppState } = useContext(AppContext);
  const [ quickSearchTerm, setQuickSearchTerm ] = useState('');

  return (
    <MainPage title="Working Sets">
      <Menu attached="top">
        <Menu.Item
          as="a"
          name="New"
          onClick={() => history.push('/workingsets/add')}
        >
          <Icon name="file outline" />
          New
        </Menu.Item>

        {workingSetsQuickSearchTerm && (
          <Menu.Item
            as="a"
            name="Show All"
            onClick={() => setAppState('workingSetsPageSize', 0)}
          >
            <Icon name="globe" />
            Show All
          </Menu.Item>
        )}

        <Menu.Menu position="right">
          <Menu.Item>
            <Input
              value={quickSearchTerm}
              placeholder="Search..."
              onKeyUp={e => {
                if (e.keyCode === KEYCODE_ENTER) {
                  setAppState('workingSetsQuickSearchTerm', e.target.value);
                }
              }}
              onChange={e => {
                setQuickSearchTerm(e.target.value);
              }}
              icon={
                <Icon
                  name={workingSetsQuickSearchTerm ? 'x' : 'search'}
                  link={!!workingSetsQuickSearchTerm}
                  circular={!!workingSetsQuickSearchTerm}
                  color={workingSetsQuickSearchTerm ? 'red' : 'black'}
                  onClick={() => {
                    setQuickSearchTerm('');
                    setAppState('workingSetsQuickSearchTerm', '');
                    setAppState('workingSetsCurrentPage', 0);
                  }}
                />
              }
            />
          </Menu.Item>
        </Menu.Menu>
      </Menu>

      <Segment attached="bottom">
        <WorkingSetsTable />
      </Segment>
    </MainPage>
  );
}

export default withRouter(WorkingSetsPage);
