import React from 'react';
import DatePicker from 'react-datepicker';
import { Form, Label, Input } from 'semantic-ui-react';

export default class WrappedDatePicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      date: new Date(),
    };
  }

  render() {
    const { input, meta, label, required } = this.props;

    return (
      <Form.Field required={required} error={meta.touched && !!meta.error}>
        <label style={{ width: '100px' }} htmlFor={input.name}>
          {label}
        </label>

        <Input
          {...input}
          style={{ width: '300px' }}
          input={
            <DatePicker
              locale="en-gb"
              dateFormat="dd/MM/yyyy"
              showMonthDropdown
              showYearDropdown
              selected={this.state.date}
              onChange={input.onChange}
            />
          }
        />

        {meta.touched && !!meta.error && (
          <Label basic color="red" pointing="left">
            {meta.error[0]}
          </Label>
        )}
      </Form.Field>
    );
  }
}
