import React from 'react';
import LogRocket from 'logrocket';
import { ApolloProvider } from '@apollo/react-hooks';
import { BrowserRouter, Switch, Redirect } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import { createStore, combineReducers } from 'redux';
import { Provider } from 'react-redux';

import * as components from './components';
import Files from './components/files';

import reducers from './reducers';
import ProtectedRoute from './ProtectedRoute.jsx';
import apolloClient from './apolloClient';
import { AppContextProvider } from './AppContext';

import './css/print.css';
import { useAuth0 } from './react-auth0-spa';

if (process.env.REACT_APP_LOGROCKET) {
  LogRocket.init(process.env.REACT_APP_LOGROCKET);
}

const store = createStore(
  combineReducers({
    ...reducers,
  }),
  {},
);

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
  }

  .actionIcon:hover {
    color: #2185d0;
    cursor: pointer;
  }
`;

function App() {
  const { loading, getTokenSilently } = useAuth0();

  if (loading) return null;

  return (
    <ApolloProvider client={apolloClient(getTokenSilently)}>
      <Provider store={store}>
        <AppContextProvider>
          <BrowserRouter>
            <div>
              <Switch>
                <ProtectedRoute
                  exact
                  path="/"
                  component={() => <Redirect to="/notices" />}
                />

                {/* Notice */}
                <ProtectedRoute
                  path={'/notice/:reference/edit'}
                  component={components.NoticeEditPage}
                />

                <ProtectedRoute
                  path="/notice/:reference/:page?"
                  component={components.NoticePage}
                />

                {/* Notices */}
                <ProtectedRoute
                  path="/notices"
                  component={components.NoticesPage}
                />

                {/* Grants */}
                <ProtectedRoute path="/grants" component={components.Grants} />

                {/* Working Sets */}
                <ProtectedRoute
                  path="/workingsets/add"
                  component={components.WorkingSetsAddPage}
                />
                <ProtectedRoute
                  path="/workingsets"
                  component={components.WorkingSetsPage}
                />

                {/* Working Set */}
                <ProtectedRoute
                  path="/workingset/:name"
                  component={components.WorkingSetPage}
                />

                {/* Sources */}
                <ProtectedRoute
                  path="/sources/add"
                  component={components.SourcesAddPage}
                />
                <ProtectedRoute
                  path="/sources"
                  component={components.SourcesPage}
                />

                {/* Users */}
                <ProtectedRoute
                  path="/users/add"
                  component={components.UsersAddPage}
                />
                <ProtectedRoute
                  exact
                  path="/users"
                  component={components.UsersPage}
                />

                {/* Solicitors */}
                <ProtectedRoute
                  exact
                  path="/solicitors"
                  component={components.SolicitorsPage}
                />
                <ProtectedRoute
                  path="/solicitors/add"
                  component={components.SolicitorsAddPage}
                />

                <ProtectedRoute
                  path="/contacts"
                  component={components.ContactsPage}
                />
                <ProtectedRoute
                  path="/library"
                  component={components.LibraryPage}
                />
                <ProtectedRoute
                  path="/activity"
                  component={components.ActivityPage}
                />

                <ProtectedRoute
                  path="/files"
                  component={Files}
                />
              </Switch>
            </div>
          </BrowserRouter>
        </AppContextProvider>
      </Provider>
      <GlobalStyle />
    </ApolloProvider>
  );
}

export default App;
