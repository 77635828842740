import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { Menu, Segment, Icon } from 'semantic-ui-react';
import MainPage from '../main/MainPage.jsx';
import WorkingSetTable from './WorkingSetTable.jsx';
import { AppContext } from '../../AppContext';

const ShowAllButton = ({ onClick, showingAll }) => (
  <Menu.Item
    as="a"
    name={showingAll ? 'Show Fewer' : 'Show All'}
    onClick={onClick}
  >
    <Icon name={showingAll ? 'filter' : 'globe'} />
    {showingAll ? 'Show Fewer' : 'Show All'}
  </Menu.Item>
);

function WorkingSetPage({ match }) {
  const { setAppState, workingSetPageSize } = useContext(AppContext);

  return (
    <MainPage title={match.params.name}>
      <Menu attached="top">
        <ShowAllButton
          showingAll={workingSetPageSize === 0}
          onClick={() => {
            if (workingSetPageSize) {
              setAppState('workingSetPageSize', 0);
              setAppState('workingSetCurrentPage', 0);
            } else {
              setAppState('workingSetPageSize', 15);
            }
          }}
        />
      </Menu>

      <Segment attached="bottom">
        <WorkingSetTable />
      </Segment>
    </MainPage>
  );
}

export default withRouter(WorkingSetPage);
