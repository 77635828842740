import React, { useState } from 'react';
import {
  Segment,
  Comment,
  Form,
  Grid,
  Icon,
  Checkbox,
  Confirm,
  Loader,
} from 'semantic-ui-react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import NotePopup from './NotePopup';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { NOTES, UPDATE_NOTE, CREATE_NOTE, DELETE_NOTE } from './gql';
import { useAuth0 } from '../../../react-auth0-spa';
import useRole from '../../../utils/useRole';

const NoticeNotesPrivateIndicator = ({ isPrivate }) =>
  isPrivate ? <Icon name="lock" /> : null;

function NoticeNotesPrivateCheckbox({ checked, onClick }) {
  const { loading, role } = useRole();

  if (loading) return null;

  if (['admin', 'senior'].includes(role)) {
    return <Checkbox label="Private" checked={checked} onClick={onClick} />;
  }

  return null;
}

function NoticeNotes() {
  const { user } = useAuth0();
  const [noteId, setNoteId] = useState('');
  const [text, setText] = useState('');
  const [isPrivate, setIsPrivate] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const { reference } = useParams();
  const { loading, data } = useQuery(NOTES, { variables: { reference } });
  const [updateNote] = useMutation(UPDATE_NOTE);
  const [deleteNote] = useMutation(DELETE_NOTE);
  const [createNote] = useMutation(CREATE_NOTE);
  const { loading: roleLoading, role } = useRole();

  function handlePrivacyToggle() {
    setIsPrivate(!isPrivate);
  }

  function handleEdit(note) {
    setNoteId(note.id);
    setText(note.text);
    setIsPrivate(note.private);
  }

  async function handleDelete() {
    await deleteNote({
      variables: {
        reference,
        input: {
          id: noteId,
        },
      },
      refetchQueries: [
        {
          query: NOTES,
          variables: { reference },
        },
      ],
    });

    setNoteId('');
    setText('');
    setIsPrivate(false);
    setConfirmDeleteOpen(false);
  }

  async function handleSave(e) {
    if (noteId) {
      await updateNote({
        variables: {
          reference,
          input: {
            id: noteId,
            text: text,
            private: isPrivate,
            user: user.preferred_username,
          },
        },
        refetchQueries: [
          {
            query: NOTES,
            variables: { reference },
          },
        ],
      });
    } else {
      await createNote({
        variables: {
          reference,
          input: {
            text: text,
            private: isPrivate,
            user: user.preferred_username,
          },
        },
        refetchQueries: [
          {
            query: NOTES,
            variables: { reference },
          },
        ],
      });
    }

    setNoteId('');
    setText('');
    setIsPrivate(false);
    setConfirmDeleteOpen(false);
  }

  if (loading || roleLoading) return <Loader />;

  const {
    notice: { notes = [] },
  } = data;

  return (
    <Segment>
      <Grid>
        <Grid.Row columns={1}>
          <Grid.Column>
            <Form>
              <Form.TextArea
                placeholder="Add note..."
                value={text}
                onChange={e => setText(e.target.value)}
              />

              <Form.Group inline>
                <Form.Button disabled={!text} onClick={handleSave}>
                  Save
                </Form.Button>

                <NoticeNotesPrivateCheckbox
                  checked={isPrivate}
                  onClick={handlePrivacyToggle}
                />
              </Form.Group>
            </Form>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row columns={1}>
          <Grid.Column>
            <Comment.Group>
              {!loading &&
                notes
                  .filter(
                    note => !note.private || ['admin', 'senior'].includes(role)
                  )
                  .map(note => (
                    <NotePopup
                      onEdit={() => handleEdit(note)}
                      onDelete={() => {
                        setNoteId(note.id);
                        setConfirmDeleteOpen(true);
                      }}
                      key={note.id}
                    >
                      <Comment>
                        <Comment.Avatar src="/avatar.png" />
                        <Comment.Content>
                          <Comment.Author>
                            {note.user}
                            <NoticeNotesPrivateIndicator
                              isPrivate={note.private}
                            />
                          </Comment.Author>
                          <Comment.Metadata>
                            {note.date === -1
                              ? 'Historical Note'
                              : moment(note.date).format('DD/MM/YY')}
                          </Comment.Metadata>
                          <Comment.Text>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: note.text.replace(/\n/g, '<br />'),
                              }}
                            />
                          </Comment.Text>
                        </Comment.Content>
                      </Comment>
                    </NotePopup>
                  ))}
            </Comment.Group>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Confirm
        open={confirmDeleteOpen}
        onCancel={() => setConfirmDeleteOpen(false)}
        onConfirm={handleDelete}
      />
    </Segment>
  );
}

export default NoticeNotes;
