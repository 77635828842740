import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-apollo';
import { Segment, Message, Menu, List, Confirm } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileUpload,
  faPencilAlt,
  faTrash,
} from '@fortawesome/pro-light-svg-icons';
import Uppy from '@uppy/core';
import { DashboardModal } from '@uppy/react';
import AwsS3Multipart from '@uppy/aws-s3-multipart';
import moment from 'moment';
import cogoToast from 'cogo-toast';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import { useAuth0 } from '../../../react-auth0-spa';
import { ADD_FILE, FILES, DELETE_FILE, UPDATE_DISPLAY_NAME } from './gql';
import RenameModal from './RenameModal';
import './NoticeFiles.css';

function NoticeFiles() {
  const [uppy, setUppy] = useState(null);
  const [selectedFileId, setSelectedFileId] = useState(null);
  const [selectedFileDisplayName, setSelectedFileDisplayName] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [renameOpen, setRenameOpen] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const { loading, user } = useAuth0();
  const { reference } = useParams();

  const [addFilesToNotice] = useMutation(ADD_FILE);
  const [deleteFile] = useMutation(DELETE_FILE);
  const [updateDisplayName] = useMutation(UPDATE_DISPLAY_NAME);

  const { loading: loadingFiles, data } = useQuery(FILES, {
    variables: { reference },
  });

  async function handleDelete() {
    await deleteFile({
      variables: { noticeReference: reference, fileId: selectedFileId },
      refetchQueries: [
        {
          query: FILES,
          variables: {
            reference,
          },
        },
      ],
    });

    setSelectedFileId(null);
    setConfirmDeleteOpen(false);
  }

  async function handleDisplayNameUpdate({ displayName }) {
    await updateDisplayName({
      variables: {
        noticeReference: reference,
        fileId: selectedFileId,
        displayName,
      },
      refetchQueries: [
        {
          query: FILES,
          variables: {
            reference,
          },
        },
      ],
    });

    setSelectedFileDisplayName('');
    setRenameOpen(false);
  }

  useEffect(() => {
    if (loading) return;

    const tempUppy = Uppy({
      meta: {
        username: user.preferred_username,
        noticeReference: reference,
      },
      onBeforeFileAdded: (currentFile, files) => {
        if (!currentFile.data.size) {
          tempUppy.info(`Skipping file because it has zero length.`);
          return false;
        }
        return true;
      },
    });

    tempUppy.use(AwsS3Multipart, {
      limit: 4,
      companionUrl: process.env.REACT_APP_UPPY_COMPANION_URL,
    });

    tempUppy.on(
      'complete',
      async ({ successful: successfulFiles, failed: failedFiles }) => {
        if (successfulFiles.length > 0) {
          try {
            await addFilesToNotice({
              variables: {
                reference,
                files: successfulFiles.map(file => ({
                  filename: file.data.name,
                  displayName: file.name,
                  uploadedBy: user.preferred_username,
                })),
              },
              refetchQueries: [{ query: FILES, variables: { reference } }],
            });

            cogoToast.success(
              `${successfulFiles.length} file${
                successfulFiles.length > 1 ? 's' : ''
              } uploaded.`
            );
          } catch (e) {
            cogoToast.error('Error uploading files. Please contact support.');
            throw e;
          }
        }

        if (failedFiles.length > 0) {
          cogoToast.warn('Some files failed to upload.');
        }
      }
    );

    setUppy(tempUppy);
  }, [user, loading, reference, addFilesToNotice]);

  if (!uppy || loading || loadingFiles) return null;

  const { files } = data.notice;

  return (
    <Segment basic>
      <Menu attached="top">
        <Menu.Item as="a" name="New" onClick={() => setModalOpen(true)}>
          <FontAwesomeIcon
            icon={faFileUpload}
            size="lg"
            style={{ marginRight: '10px' }}
          />
          Upload
        </Menu.Item>
      </Menu>

      {files.length === 0 ? (
        <Segment attached="bottom">
          <Message size="large">No Files for this Case</Message>
        </Segment>
      ) : (
        <List
          divided
          relaxed
          style={{ marginLeft: '10px', marginRight: '10px' }}
        >
          {files.map(file => (
            <List.Item key={Math.random()}>
              <List.Content>
                <List.Header style={{ paddingBottom: '5px' }}>
                  <a href={file.url} target="_blank" rel="noopener noreferrer">
                    {file.displayName}
                    {file.displayName !== file.filename
                      ? ` - ${file.filename}`
                      : ''}
                  </a>
                  <Segment basic compact floated="right">
                    <FontAwesomeIcon
                      className="fileIcon"
                      size="lg"
                      color="black"
                      icon={faPencilAlt}
                      style={{ marginRight: '5px' }}
                      title="Edit Display Name"
                      onClick={() => {
                        setSelectedFileId(file.id);
                        setSelectedFileDisplayName(file.displayName);
                        setRenameOpen(true);
                      }}
                    />
                    <FontAwesomeIcon
                      className="fileIcon"
                      size="lg"
                      color="black"
                      icon={faTrash}
                      title="Delete"
                      onClick={() => {
                        setSelectedFileId(file.id);
                        setConfirmDeleteOpen(true);
                      }}
                    />
                  </Segment>
                </List.Header>
                <List.Description as="a">
                  Uploaded {moment(file.uploadedAt).format('DD/MMM/YYYY')} by{' '}
                  {file.uploadedBy}{' '}
                </List.Description>
              </List.Content>
            </List.Item>
          ))}
        </List>
      )}

      <DashboardModal
        uppy={uppy}
        open={modalOpen}
        closeModalOnClickOutside
        closeAfterFinish
        allowMultipleUploads={false}
        showLinkToFileUploadResult={false}
        onRequestClose={() => setModalOpen(false)}
      />

      <Confirm
        size="mini"
        confirmButton="Delete"
        open={confirmDeleteOpen}
        onCancel={() => {
          setSelectedFileId(null);
          setConfirmDeleteOpen(false);
        }}
        onConfirm={handleDelete}
      />

      <RenameModal
        open={renameOpen}
        currentName={selectedFileDisplayName}
        onSave={handleDisplayNameUpdate}
      />
    </Segment>
  );
}

export default NoticeFiles;
