import React from 'react';
import { Route, Switch } from 'react-router-dom';
import NoticesAddPage from './new/NoticesAddPage.jsx';
import SearchNoticePage from './search';
import NoticesList from './list/NoticesList.jsx';
import NoticesCreateWorkingSetPage from './workingset/NoticesCreateWorkingSetPage.jsx';

export default function NoticesPage() {
  return (
    <Switch>
      <Route path={'/notices/add'} component={NoticesAddPage} />
      <Route
        path={'/notices/workingset'}
        component={NoticesCreateWorkingSetPage}
      />
      <Route path={'/notices/search'} component={SearchNoticePage} />
      <Route path={'/notices'} component={NoticesList} />
    </Switch>
  );
}
