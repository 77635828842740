import gql from 'graphql-tag';

export const DELETE_NOTICE = gql`
  mutation deleteNotice($_id: ObjectID!) {
    deleteNotice(_id: $_id) {
      _id
      deleted
    }
  }
`;

export const NOTICE = gql`
  query Notice($reference: String!) {
    notice(reference: $reference) {
      _id
      noticedate
      source
      informant
      sex
      reference
      familyname
      familynameakas
      maidenname
      firstnames
      firstnameakas
      livedat
      diedat
      diedon
      estatevalue
    }
  }
`;
