import gql from 'graphql-tag';

export const WORKING_SETS = gql`
  query Notice($reference: String!) {
    notice(reference: $reference) {
      _id
      workingsets {
        name
      }
    }
  }
`;
